<template>
  <div>
    <h2 class="title is-5">
      {{ $t('services.subsequentPresentation.header') }}
    </h2>
    <h3>{{ $t('services.subsequentPresentation.subheader') }}</h3>
    <p>{{ $t('services.subsequentPresentation.intro') }}</p>
    <SendMessage type="subsequentPresentation" />
  </div>
</template>

<script>
import SendMessage from '@/components/Selfservice/Actions/SendMessage'

export default {
  name: 'SelfserviceActionSubsequentPresentation',
  components: { SendMessage }
}
</script>

<style lang="scss" scoped></style>
