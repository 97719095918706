<template>
  <div class="field">
    <Label v-if="label" :label="label" :required="required" />
    <p class="help" v-show="preContent">{{ preContent }}</p>
    <div class="control is-clearfix has-icons-right" :class="{ small: small }">
      <input
        v-if="inputType === 'text'"
        @input="onInput($event.target.value)"
        @blur="onBlur()"
        v-model="inputVal"
        :name="name"
        type="text"
        autocomplete="on"
        :class="inputClass"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        v-mask="inputMask"
      />
      <Money
        v-if="inputType === 'money'"
        @blur.native="enableMoneyValidation()"
        v-model="inputVal"
        v-bind="money"
        :name="name"
        :class="inputClass"
        :disabled="disabled"
        :readOnly="readonly"
      />
      <span v-if="required" class="icon is-small is-right">
        <check-circle-icon
          v-if="validationState === 'success'"
          size="1x"
          class="check-circle-icon"
        ></check-circle-icon>
        <circle-icon
          v-else
          size="1x"
          class="circle-icon"
          :class="validationState === 'invalid' ? 'is-danger' : ''"
        ></circle-icon>
      </span>
    </div>
    <transition
      appear
      enter-active-class="bounceInLeft"
      leave-active-class="fadeOut"
      v-if="errorMessage"
    >
      <span class="tooltip">{{ errorMessage }}</span>
    </transition>
    <p class="help" v-show="postContent">{{ postContent }}</p>
  </div>
</template>

<script>
import Label from '@/components/Form/Label'
import { CircleIcon, CheckCircleIcon } from 'vue-feather-icons'
import { Money } from 'v-money'

export default {
  components: { Label, CircleIcon, CheckCircleIcon, Money },
  props: {
    label: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    name: {
      type: String
    },
    inputType: {
      type: String,
      default: 'text'
    },
    mask: {
      type: Object
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    preContent: {
      type: String
    },
    postContent: {
      type: String
    },
    validationState: {
      type: String
    },
    validated: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      validateMoney: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '€',
        precision: 2,
        masked: false
      }
    }
  },
  computed: {
    inputVal: {
      get: function() {
        return this.value
      },
      set: function(value) {
        this.$emit('input', value)
      }
    },
    inputMask() {
      return this.mask ? this.mask : {}
    },
    inputClass() {
      let inputClass = ['input', 'is-rounded']
      if (this.required) inputClass.push('required')
      if (this.validationState === 'invalid') inputClass.push('is-danger')
      if (this.validationState === 'success') inputClass.push('is-success')
      return inputClass
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onBlur() {
      this.$emit('blur')
    },
    enableMoneyValidation() {
      this.validateMoney = true
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped>
.control {
  &.small {
    width: 60%;
  }
}
</style>
