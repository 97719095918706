<template>
  <div>
    <h2 class="title is-5">{{ $t('services.complaint.header') }}</h2>
    <h3>
      {{ $t('services.complaint.subheader') }}
    </h3>
    <p>{{ $t('services.complaint.intro') }}</p>
    <SendMessage type="complaint" />
  </div>
</template>

<script>
import SendMessage from '@/components/Selfservice/Actions/SendMessage'

export default {
  name: 'SelfserviceActionComplaint',
  components: { SendMessage }
}
</script>

<style lang="scss" scoped></style>
