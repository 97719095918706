import dottie from 'dottie'

export const portalMixin = {
  methods: {
    portalIdentifier(claim, type) {
      if (type === 'customernumber') {
        return dottie.get(claim, 'claimDetails.customerNumber', 'n/a')
      }
      return dottie.get(claim, 'filenumber', 'n/a')
    },
    portalIdentifierList(claims, type, showPaid = true) {
      let filteredClaims = claims
      if (!showPaid) filteredClaims = claims.filter(c => c.balance)
      if (type === 'customernumber') {
        return filteredClaims.map(c =>
          dottie.get(c, 'claimDetails.customerNumber', 'n/a')
        )
      }
      return filteredClaims.map(c => dottie.get(c, 'filenumber', 'n/a'))
    },
    getClaimByIdentifier(claims, identifier, type) {
      if (type === 'customernumber') {
        return claims.find(c => c.claimDetails.customerNumber === identifier)
      }
      return claims.find(c => c.fileNumber === identifier)
    }
  }
}
