import axios from 'axios'

export default token => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  if (!token) token = localStorage.getItem('portalToken')

  if (token) {
    headers['X-Authorization'] = `Bearer ${token}`
  }

  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )

  return axios.create({
    baseURL: '/selfservice/api',
    withCredentials: true,
    headers: headers
  })
}
