<template>
  <div
    class="columns is-gapless is-mobile is-vcentered paymentmethod"
    :class="{ disabled: disabled }"
  >
    <span class="column is-2">
      <input
        type="radio"
        :id="realValue"
        :checked="realValue === value"
        :disabled="step === 1"
        @change="onChange($event.target.checked)"
      />
    </span>
    <label :for="realValue" class="column is-6">
      <img
        class="logo"
        :src="require(`@/assets/images/logos/${realValue}.svg`)"
        alt
      />
    </label>
    <div class="column is-4 has-margin-left-10">
      <InfoIcon
        v-if="isValidComponent"
        @click="modal = true"
        size="1x"
        class="has-cursor-pointer"
      ></InfoIcon>
    </div>

    <Modal :active="modal" @modalClosed="modal = false">
      <template v-slot:content>
        <component :is="infoComponent"></component>
      </template>
    </Modal>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import { InfoIcon } from 'vue-feather-icons'
import Modal from '@/components/Modal'
import { matomoMixin } from '@/mixins/matomo'

export default {
  name: 'PaymentMethod',
  components: {
    InfoIcon,
    Modal
  },
  mixins: [matomoMixin],
  props: {
    value: {
      type: String
    },
    realValue: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      modal: false,
      availablePaymentMethods: [
        'paypal',
        'sofort',
        'giropay',
        'rebuy',
        'barzahlen'
      ]
    }
  },
  computed: {
    step: get('payment/step'),
    isValidComponent() {
      return this.availablePaymentMethods.includes(this.realValue)
    },
    loadedComponent() {
      return this.isValidComponent
        ? `Payment/Info/${this.realValue}`
        : `Undefined`
    },
    infoComponent() {
      return () => import(`@/components/${this.loadedComponent}.vue`)
    }
  },
  methods: {
    onChange(checked) {
      this.matomoButtonEvent(this.realValue)
      if (checked) this.$emit('input', this.realValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  height: 30px;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
.paymentmethod {
  &.disabled {
    .logo {
      filter: grayscale(100%);
      opacity: 0.4;
    }
  }
  &:hover {
    .logo {
      transform: scale(1.1);
    }
  }
}
</style>
