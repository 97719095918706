<template>
  <MountingPortal v-if="portalElement" :mountTo="portalElement">
    <router-view />
  </MountingPortal>
  <router-view v-else />
</template>

<script>
export default {
  props: {
    portalElement: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped></style>
