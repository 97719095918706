<template>
  <div>
    <label class="label">
      {{ label }}
      <span v-if="required">*</span>
      <span v-if="showIcon">
        <check-circle-icon
          v-if="success"
          size="1x"
          class="check-circle-icon"
        ></check-circle-icon>
        <circle-icon
          v-else
          size="1x"
          class="circle-icon"
          :class="validationEnabled ? 'is-danger' : ''"
        ></circle-icon>
      </span>
    </label>
    <!-- TODO: onBlur is not called from uppy, so we call it in mounted() -->
    <div
      :class="validationState === 'invalid' ? 'is-danger' : ''"
      id="uppyBox"
      @blur="onBlur()"
      v-show="!disabled"
    ></div>
    <nav class="panel">
      <a class="panel-block" v-for="(file, index) in value" :key="index">
        <span>{{ file.originalFilename }}</span>
        <XCircleIcon @click="deleteUpload(index)" />
      </a>
    </nav>
  </div>
</template>

<script>
import { CircleIcon, CheckCircleIcon, XCircleIcon } from 'vue-feather-icons'
import merge from 'webpack-merge'

const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')
let uppy

const localeMappings = {
  de: 'de_DE',
  en: 'en_US',
  ar: 'ar_SA',
  cs: 'cs_CZ',
  da: 'da_DK',
  el: 'el_GR',
  es: 'es_ES',
  fa: 'fa_IR',
  fi: 'fi_FI',
  fr: 'fr_FR',
  hu: 'hu_HU',
  id: 'id_ID',
  it: 'it_IT',
  ja: 'ja_JP',
  nl: 'nl_NL',
  pt: 'pt_BR',
  ru: 'ru_RU',
  sr: 'sr_RS_Latin',
  sv: 'sv_SE',
  th: 'th_TH',
  tr: 'tr_TR',
  zh: 'zh_CN'
}

export default {
  components: {
    CircleIcon,
    CheckCircleIcon,
    XCircleIcon
  },
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array
    },
    limit: {
      type: Number
    },
    options: {
      type: Object
    },
    dashboardOptions: {
      type: Object
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    validationState: {
      type: String
    },
    validated: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      defaultOptions: {
        debug: true,
        autoProceed: false,
        restrictions: {
          maxFileSize: 1000000,
          maxNumberOfFiles: 2,
          minNumberOfFiles: 1,
          allowedFileTypes: [
            'image/jpeg',
            'image/gif',
            'application/pdf',
            'application/msword'
          ]
        }
      },
      defaultDashboardOptions: {
        target: '#uppyBox',
        inline: true,
        width: '100%',
        height: '300px',
        showProgressDetails: true,
        proudlyDisplayPoweredByUppy: false,
        acceptedFilesMessage: true,
        disablePageScrollWhenModalOpen: false
      },
      validationEnabled: false
    }
  },

  computed: {
    uppyOptions() {
      return merge(this.defaultOptions, this.options)
    },
    uppyDashboardOptions() {
      return merge(this.defaultDashboardOptions, this.dashboardOptions)
    }
  },

  created() {
    this.initUppy()
  },
  mounted() {
    this.onBlur()
  },

  destroyed() {
    if (uppy) uppy.close()
  },

  methods: {
    async initUppy() {
      if (uppy) uppy.close()

      let options = this.uppyOptions
      let dashboardOptions = this.uppyDashboardOptions

      if (this.$i18n)
        options.locale = await this.getUppyLocale(this.$i18n.locale)
      if (dashboardOptions.acceptedFilesMessage)
        dashboardOptions.note = this.getDashboardNote(
          options.restrictions.allowedFileTypes
        )
      if (this.limit) options.restrictions.maxNumberOfFiles = this.limit

      uppy = new Uppy(options)

      uppy
        .use(XHRUpload, {
          endpoint: '/selfservice/api/contact/uploads',
          fieldName: 'file'
        })
        .use(Dashboard, dashboardOptions)

      uppy.getPlugin('Dashboard').openModal()

      uppy.on('upload-success', (file, response) => {
        this.updateModel(response.body)
      })

      uppy.on('complete', result => {
        setTimeout(() => {
          uppy.getPlugin('Dashboard').closeModal()
          uppy.reset()
        }, 1400)
      })
    },
    updateModel(value) {
      this.$emit('uploaded', value)
    },
    deleteUpload(index) {
      this.$emit('deleteUpload', index)
    },
    async getUppyLocale(locale) {
      const uppyLocale = localeMappings[locale] || 'de_DE'
      return import(`@uppy/locales/lib/${uppyLocale}.js`)
    },
    getDashboardNote(acceptedFiles) {
      let message = this.$i18n
        ? this.$t('form.upload.acceptedFiles')
        : 'Akzeptierte Dateien'

      if (acceptedFiles.length > 0) {
        message += acceptedFiles
          .map(f => this.$t('form.upload.files')[f])
          .join(',')
      }

      return message
    },
    onBlur() {
      if (this.validated) this.validationEnabled = true
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss">
@import '@uppy/core/src/style.scss';
@import '@uppy/dashboard/src/style.scss';

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.uppy-StatusBar.is-waiting,
.uppy-DashboardContent-bar,
.uppy-StatusBar-actions {
  background-color: transparent !important;
}

.is-danger {
  .uppy-Dashboard-inner {
    border: 1px solid rgba(185, 10, 10, 0.15);
    box-shadow: 0 0 0 0.125em rgba(185, 0, 0, 0.25);
  }

  .uppy-Dashboard-innerWrap {
    background-color: rgba(255, 0, 0, 0.03);
  }
}

.imgupload {
  border: 2px dashed #d8dce5;
  color: #d8dce5;
  background-color: #f5f7fa;
  border-radius: 4px;

  .is-overlay {
    display: flex;

    &:not(.disabled):hover {
      color: #359fe1;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  &.has-value {
    border: none;
    border-radius: 0;

    img {
      border: 1px solid #d8dce5;
    }

    .is-overlay {
      opacity: 0;
      background-color: rgba(255, 255, 255, 0.8);
      border: 2px dashed #359fe1;

      &:not(.disabled):hover {
        opacity: 1;
      }
    }
  }

  .delete {
    position: absolute;
    top: -8px;
    right: -8px;
    border: solid 1px #fff;
    background-color: #666666;
  }
  .delete:hover,
  .delete:focus {
    background-color: #359fe1;
  }
}

button:focus {
  outline: 0;
}
</style>
