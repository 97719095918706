<template>
  <div v-if="hasClaim">
    <div v-if="claim.status === 'lawyer'">
      <div class="container content has-margin-top-60">
        <p>{{ $t('payment.general.file-transferred-to-lawyer') }}</p>
      </div>
    </div>
    <div class v-else>
      <Payment />
    </div>
    <div class="container has-margin-top-20 has-margin-bottom-20">
      <div class="columns">
        <div class="column">
          <a href="/">&larr; {{ $t('navigation.home') }}</a>
          <div v-if="hasPublicContact">
            <a href="/selfservice/contact">
              &larr; {{ $t('navigation.contact') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container is-marginless is-fluid has-padding-top-20"
      v-if="hasLogin"
    >
      <div class="columns">
        <div class="column">
          <Login :componentName="identifierComponent" v-if="!isAuthenticated" />
          <Internal v-if="isAuthenticated" />
        </div>
      </div>
    </div>
    <component v-if="provider" :is="providerComponent"></component>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import Payment from '@/components/Payment'
import Login from '@/components/Login'
import Internal from '@/components/Selfservice/Internal'

export default {
  name: 'Selfservice',
  components: {
    Payment,
    Login,
    Internal
  },
  data() {
    return {
      provider: this.$route.meta.provider
    }
  },
  computed: {
    claim: get('portal/claim'),
    error: get('portal/error'),
    identifier: sync('portal/identifier'),
    identifierType: sync('portal/identifierType'),
    customernumber: sync('auth/customernumber'),
    filenumber: sync('auth/filenumber'),
    isAuthenticated: get('auth/isAuthenticated'),
    hasLogin() {
      return this.$appconfig.get('login', false)
    },
    hasClaim() {
      return !!this.claim.filenumber
    },
    hasError() {
      return this.error
    },
    hasPublicContact() {
      return this.$appconfig.get('publicContact', false)
    },
    identifierComponent() {
      return this.$appconfig.get('identifier.credentials', 'Undefined')
    },
    providerComponent() {
      return () => import(`@/components/Payment/Widget/${this.provider}.vue`)
    }
  },
  async created() {
    if (!this.hasClaim) {
      this.identifier = this.$route.params.id
      await this.requestClaim({
        refresh: true
      })
    }
    if (this.hasError) return this.go('home')
    this.setPayment(this.claim)
    this.setMethods({ methods: this.$appconfig.get('payment.methods', []) })
    if (this.identifierType && this.hasLogin) {
      this[this.identifierType] = this.identifier
    }
  },
  methods: {
    requestClaim: call('portal/requestClaim'),
    setPayment: call('payment/setPayment'),
    setMethods: call('payment/setMethods'),
    go(to) {
      this.$router.push({
        name: to,
        params: { lang: this.$i18n.locale }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
