<template>
  <div class="container content">
    <h2 class="title is-5 is-marginless">{{ $t('login.header') }}</h2>
    <div class="columns">
      <div class="column" :class="loginType === 'full' ? 'is-10' : 'is-5'">
        <form @submit.prevent="onSubmit" method="POST">
          <div class="field is-grouped login--field" style="margin-left: 0">
            <Input
              v-if="loginType === 'full'"
              :label="$t(`login.${identifierType}.label`)"
              :placeholder="$t(`login.${identifierType}.placeholder`)"
              v-model="identifierKey"
              :validationState="validationState($v[identifierType])"
              @blur="$v[identifierType].$touch"
              required
              style="margin-right: 2rem"
            />

            <component :is="componentFile" :validation="$v"></component>
          </div>
          <Error v-if="error" class="notification">
            {{ $t(errorName) }}
          </Error>
          <Checkbox
            v-model="persistToken"
            :label="$t('login.persist')"
            class="is-marginless save--me"
          />
          <button :disabled="!isValid" class="button is-primary">
            {{ $t('button.next') }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import { validationMixin } from 'vuelidate'
import { customValidationMixin } from '@/mixins/validation'
import { matomoMixin } from '@/mixins/matomo'
import { required } from 'vuelidate/lib/validators'
import { isDate } from '@/lib/customValidators'
import Input from '@/components/Form/Input'
import Checkbox from '@/components/Form/Checkbox'
import Error from '@/components/Error'

export default {
  mixins: [validationMixin, customValidationMixin, matomoMixin],
  components: {
    Checkbox,
    Input,
    Error
  },
  props: {
    componentName: {
      type: String,
      required: true
    },
    loginType: String
  },
  validations() {
    const validation = {}
    if (this.identifierType) validation[this.identifierType] = { required }
    if (this.componentName === 'dob') {
      validation['dob'] = { isDate }
    }
    if (this.componentName === 'name') {
      validation['firstname'] = { required }
      validation['lastname'] = { required }
    }

    return validation
  },
  data: () => {
    return {
      validComponents: {
        dob: 'DateOfBirth',
        name: 'Name'
      }
    }
  },
  computed: {
    credentials: sync('auth/credentials'),
    filenumber: sync('auth/filenumber'),
    customernumber: sync('auth/customernumber'),
    token: get('auth/token'),
    persistToken: sync('auth/persistToken'),
    dob: get('auth/dob'),
    firstname: get('auth/firstname'),
    lastname: get('auth/lastname'),
    identifier: get('portal/claimIdentifier'),
    identifierType: get('portal/identifierType'),
    error: get('auth/error'),
    errorName() {
      if (this.error === 'TECHNICALERROR') return `errors.${this.error}`
      return `errors.${this.error}_${this.componentName.toUpperCase()}`
    },
    identifierKey: {
      get() {
        return this[this.identifierType]
      },
      set(value) {
        this[this.identifierType] = value
      }
    },
    pwField() {
      return this.$appconfig.get('identifier.credentials', false)
    },
    isValidComponent() {
      return this.validComponents[this.componentName]
    },
    loadedComponent() {
      return this.isValidComponent
        ? `Login/${this.isValidComponent}`
        : `Undefined`
    },
    componentFile() {
      return () => import(`@/components/${this.loadedComponent}.vue`)
    },
    isValid() {
      return !this.$v.$invalid
    }
  },
  created() {
    this.credentials['key'] = this.identifierType
    this.credentials['pw'] = this.pwField
  },
  methods: {
    login: call('auth/login'),
    onSubmit() {
      this.matomoButtonEvent('selfservice-internal-login')
      this.login()
    }
  }
}
</script>

<style lang="css" scoped></style>
