<template>
  <div class="container">
    <h1>{{ $t('login.service') }}</h1>
    <Login
      v-show="ready"
      :componentName="identifierComponent"
      loginType="full"
    />
    <p class="content" v-html="$t('login.info')"></p>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import dottie from 'dottie'
import Login from '@/components/Login'

export default {
  components: {
    Login
  },
  data: () => {
    return {
      ready: false,
      validComponents: {
        dob: 'DateOfBirth',
        name: 'Name'
      }
    }
  },
  computed: {
    claims: get('portal/claims'),
    isAuthenticated: get('auth/isAuthenticated'),
    identifierType() {
      return this.$appconfig.get('identifier.type', 'filenumber')
    },
    identifierComponent() {
      return this.$appconfig.get('identifier.credentials', 'Undefined')
    },
    isValidComponent() {
      return this.validComponents[this.componentName]
    },
    loadedComponent() {
      return this.isValidComponent
        ? `Login/${this.isValidComponent}`
        : `Undefined`
    },
    componentFile() {
      return () => import(`@/components/${this.loadedComponent}.vue`)
    },
    routeIdentifier() {
      if (this.claims.length) {
        if (this.identifierType === 'customernumber') {
          return dottie.get(
            this.claims[0],
            'claimDetails.customerNumber',
            false
          )
        }
        return dottie.get(this.claims[0], 'filenumber', false)
      }
      return false
    }
  },
  watch: {
    isAuthenticated: function(value) {
      if (value) this.redirect()
    }
  },
  async created() {
    if (this.isAuthenticated) this.redirect()
    this.ready = true
  },
  methods: {
    getClaims: call('portal/requestClaims'),
    async redirect() {
      await this.getClaims({ refresh: true })
      if (this.routeIdentifier) {
        this.$router.push({
          name: 'selfserviceClaim',
          params: {
            lang: this.$i18n.locale,
            id: this.routeIdentifier,
            accesstype: 'W'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
