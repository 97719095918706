<template>
  <div>
    <h2 class="title is-5">{{ $t('services.arrangeDeferral.header') }}</h2>
    <p>{{ $t('services.arrangeDeferral.intro') }}</p>
    <SendMessage type="arrangeDeferral" />
  </div>
</template>

<script>
import SendMessage from '@/components/Selfservice/Actions/SendMessage'

export default {
  name: 'SelfserviceActionDeferral',
  components: { SendMessage }
}
</script>
