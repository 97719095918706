<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style></style>

<script>
import { call, get } from 'vuex-pathify'
import { portalMixin } from '@/mixins/portal'
import bus from '@/bus'
export default {
  mixins: [portalMixin],
  computed: {
    isAuthenticated: get('auth/isAuthenticated'),
    claims: get('portal/claims'),
    identifier: get('portal/identifier'),
    identifierType: get('portal/identifierType')
  },
  created() {
    /**
     * reset portal data on logout
     */
    bus.subscribe(this, ['auth/handleLogout'], () => {
      this.resetAuthUserData()
    })
    bus.subscribe(this, ['portal/handleSuccess'], event => {
      if (event.payload.prop === 'claims' && this.isAuthenticated) {
        const claim = this.getClaimByIdentifier(
          this.claims,
          this.identifier,
          this.identifierType
        )
        if (!claim) return this.logout()
        this.getAddress({ refresh: true })
      }
    })
  },
  methods: {
    resetAuthUserData: call('portal/resetAuthUserData'),
    getAddress: call('portal/requestAddress'),
    logout: call('auth/logout')
  }
}
</script>
