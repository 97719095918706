<template>
  <div class="field">
    <label class="label">
      {{ label }}
      <span v-if="required">*</span>
      <span v-if="required" class="icon">
        <check-circle-icon
          v-if="validationState === 'success'"
          size="1x"
          class="check-circle-icon"
        ></check-circle-icon>
        <circle-icon
          v-else
          size="1x"
          class="circle-icon"
          :class="validationState === 'invalid' ? 'is-danger' : ''"
        ></circle-icon>
      </span>
    </label>
    <p class="help" v-show="preContent">{{ preContent }}</p>
    <div class="control is-clearfix has-icons-right">
      <textarea
        v-if="inputType === 'text'"
        @input="onInput($event.target.value)"
        @blur="onBlur()"
        :name="name"
        v-model="inputVal"
        type="text"
        autocomplete="on"
        class="input is-rounded"
        :class="inputClass"
      />
    </div>
    <transition
      appear
      enter-active-class="bounceInLeft"
      leave-active-class="fadeOut"
      v-if="errorMessage"
    >
      <span class="tooltip">{{ errorMessage }}</span>
    </transition>
    <p class="help" v-show="postContent">{{ postContent }}</p>
  </div>
</template>

<script>
import { CircleIcon, CheckCircleIcon } from 'vue-feather-icons'

export default {
  components: { CircleIcon, CheckCircleIcon },
  props: {
    label: {
      type: String
    },
    name: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    inputType: {
      type: String,
      default: 'text'
    },
    mask: {
      type: Object
    },
    required: {
      type: Boolean,
      default: false
    },
    preContent: {
      type: String
    },
    postContent: {
      type: String
    },
    validationState: {
      type: String
    },
    validated: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String
    }
  },
  data() {
    return {
      inputVal: this.value,
      validateMoney: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '€',
        precision: 2,
        masked: false
      }
    }
  },
  computed: {
    inputMask() {
      return this.mask ? this.mask : {}
    },
    inputClass() {
      // let inputClass = ['input']
      // if (this.required) inputClass.push('required')
      // if (!this.success && this.validationEnabled) inputClass.push('is-danger')
      // if (this.success) inputClass.push('is-success')
      // return inputClass

      let inputClass = ['input']
      if (this.required) inputClass.push('required')
      if (this.validationState === 'invalid') inputClass.push('is-danger')
      if (this.validationState === 'success') inputClass.push('is-success')
      return inputClass
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onInputMoney(value) {
      if (!this.validateMoney) return
      this.$emit('input', value)
    },
    onBlur() {
      // if (this.validated) this.validationEnabled = true
      this.$emit('blur')
    },
    enableMoneyValidation() {
      this.validateMoney = true
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped></style>
