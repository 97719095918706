import { helpers } from 'vuelidate/lib/validators'
import AppConfig from '@/appconfig'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const regexIdentifier = helpers.regex(
  'regexIdentifier',
  new RegExp(AppConfig.get('identifier.regex'), 'i')
)
const isDate = value => dayjs(value, 'YYYY-MM-DD').isValid()

const minAmount = value => parseInt(value) >= 5

const minDate = date => dayjs(date, 'YYYY-MM-DD').isAfter(dayjs(), 'day')

const maxDate = date => dayjs(date, 'YYYY-MM-DD').isBefore(dayjs(), 'day')

export { regexIdentifier, isDate, minAmount, minDate, maxDate }
