<template>
  <div class="container">
    <Modal :active="!!success" :header="false" @modalClosed="modalClose">
      <template v-slot:content>
        <div class="notification is-success">
          <button class="delete" @click="modalClose"></button>
          <h3 class="has-text-white">
            {{ $t('services.addressChange.thanks') }}
          </h3>
          {{ $t('services.addressChange.success') }}
        </div>
      </template>
    </Modal>
    <Modal :active="!!error" :header="false" @modalClosed="error = false">
      <template v-slot:content>
        <div class="notification is-danger">
          <button class="delete" @click="error = false"></button>
          {{ $t(`errors.${error}`) }}
        </div>
      </template>
    </Modal>

    <h2 class="title is-5">{{ $t('services.addressChange.header') }}</h2>
    <p>{{ $t('services.addressChange.intro') }}</p>
    <form method="POST" @submit.prevent="submitHandler">
      <div class="field is-grouped">
        <Select
          :label="$t('form.labels.salutation')"
          :options="salutationOptions"
          v-model="address.salutation"
          required
          :translateValues="true"
          :translateOptions="true"
          default="mrs"
        />
        <Input
          :label="$t('form.labels.title')"
          v-model="address.title"
          :validationState="validationState($v.address.title)"
          @input.native="$v.address.title.$touch"
        />
        <Input
          :label="$t('form.labels.givenName')"
          v-model="address.givenName"
          :validationState="validationState($v.address.givenName)"
          @input.native="$v.address.givenName.$touch"
          required
        />
        <Input
          :label="$t('form.labels.surname')"
          v-model="address.surname"
          :validationState="validationState($v.address.surname)"
          @input.native="$v.address.surname.$touch"
          required
        />
        <Input
          :label="$t('form.labels.dayOfBirth')"
          v-model="dob"
          :validationState="validationState($v.address.dayOfBirth)"
          @input.native="$v.address.dayOfBirth.$touch"
          :mask="maskDob"
          required
        />
      </div>
      <div class="field is-grouped">
        <Input
          :label="$t('form.labels.streetAddress')"
          v-model="address.streetAddress"
          :validationState="validationState($v.address.streetAddress)"
          @input.native="$v.address.streetAddress.$touch"
          required
        />
        <Input
          :label="$t('form.labels.streetNumber')"
          v-model="address.streetNumber"
          :validationState="validationState($v.address.streetNumber)"
          @input.native="$v.address.streetNumber.$touch"
          required
        />
      </div>

      <div class="field is-grouped">
        <Input
          :label="$t('form.labels.zip')"
          v-model="address.zip"
          :validationState="validationState($v.address.zip)"
          @input.native="$v.address.zip.$touch"
          required
        />
        <Input
          :label="$t('form.labels.city')"
          v-model="address.city"
          :validationState="validationState($v.address.city)"
          @input.native="$v.address.city.$touch"
          required
        />
        <div class="field">
          <div class="control">
            <Label :label="$t('form.labels.country')" :required="true" />
            <div class="select is-empty">
              <Countries v-model="address.country"></Countries>
            </div>
          </div>
        </div>
        <Select
          :label="$t('form.labels.reasonForChanges')"
          :options="reasonForChangesOptions"
          v-model="address.reasonForChanges"
          required
          :translateOptions="true"
          default="other"
        />
      </div>
      <button :disabled="$v.$invalid" @click.prevent="submitHandler">
        {{ $t('services.addressChange.submit') }}
      </button>
    </form>
  </div>
</template>

<script>
import { sync, call } from 'vuex-pathify'
import dayjs from 'dayjs'
import Input from '@/components/Form/Input'
import Label from '@/components/Form/Label'
import Countries from '@/components/Form/Countries'
import Select from '@/components/Form/Select'
import Modal from '@/components/Modal'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { customValidationMixin } from '@/mixins/validation'

export default {
  name: 'SelfserviceActionsAddressChange',
  components: { Input, Label, Countries, Select, Modal },
  mixins: [validationMixin, customValidationMixin],
  validations() {
    let validation = {
      address: {
        salutation: {
          required
        },
        givenName: {
          required
        },
        surname: {
          required
        },
        dayOfBirth: {
          required
        },
        streetAddress: {
          required
        },
        streetNumber: {
          required
        },
        zip: {
          required
        },
        city: {
          required
        },
        country: {
          required
        }
      }
    }

    return validation
  },
  data() {
    return {
      reasonForChangesOptions: [
        'other',
        'secondary_address',
        'marriage',
        'relocation',
        'address_correction',
        'name_correction'
      ],
      salutationOptions: ['mrs', 'mr', 'divers']
    }
  },
  computed: {
    address: sync('portal/address'),
    error: sync('portal/addressChangeError'),
    success: sync('portal/addressChangeSuccess'),
    currentAction: sync('portal/currentAction'),
    maskDob() {
      return {
        alias: 'datetime',
        inputFormat: 'dd.mm.yyyy',
        placeholder: '__.__.____'
      }
    },
    dob: {
      get() {
        return dayjs(this.address.dayOfBirth, 'YYYY-MM-DD').format('DD.MM.YYYY')
      },
      set(value) {
        this.address.dayOfBirth = dayjs(value, 'DD.MM.YYYY').format(
          'YYYY-MM-DD'
        )
      }
    }
  },
  created() {
    this.dob = this.dob
  },
  methods: {
    submitHandler: call('portal/requestAddressChange'),
    modalClose() {
      this.success = false
      this.currentAction = ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
