<template>
  <div v-if="claims" id="overview">
    <div class="overview-title">
      <h2 class="title is-5">
        {{ $t('services.label.overview') }}
        {{ $t('services.address.of', { name: fullName }) }}
      </h2>
      <button class="button" @click.prevent="logout">
        {{ $t('services.label.logout') }}
      </button>
      <button
        class="button is-pulled-right"
        @click.prevent="callAction('address_change')"
      >
        {{ $t('services.label.addressChange') }}
      </button>
    </div>

    <table class="table is-responsive">
      <thead>
        <tr>
          <th class="is-table-head has-text">
            {{ $t(`services.label.${identifierType}`) }}
          </th>
          <th></th>
          <th class="is-table-head has-text has-text-right">
            {{ $t('services.label.balance') }}
            <sup>1)</sup>
          </th>
          <th class="is-table-head has-text has-padding-left-40">
            {{ $t('services.label.detail') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <Claim v-for="claim in claims" :key="claim.filenumber" :claim="claim" />
      </tbody>
    </table>
    <div class="notification has-text-centered">
      <p v-html="$t('services.message.info.claims')"></p>
      <ButtonInkasso />
    </div>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import VueScrollTo from 'vue-scrollto'
import Claim from '@/components/Selfservice/Claim'
import ButtonInkasso from '@/components/Selfservice/ButtonInkasso'
import { matomoMixin } from '@/mixins/matomo'

export default {
  name: 'SelfserviceOverview',
  components: { Claim, ButtonInkasso },
  mixins: [matomoMixin],
  computed: {
    claims: get('portal/claims'),
    identifierType: get('portal/identifierType'),
    currentAction: sync('portal/currentAction'),
    address: get('portal/address'),
    fullName: get('portal/fullName')
  },
  data() {
    return {
      scrollOptions: {
        easing: 'ease-in',
        offset: -160
      }
    }
  },
  watch: {
    currentAction(action) {
      if (!action) {
        VueScrollTo.scrollTo('#overview', '500', this.scrollOptions)
      }
    }
  },
  methods: {
    logout: call('auth/logout'),
    callAction(name) {
      this.currentAction = name
      this.matomoButtonEvent(name)
      VueScrollTo.scrollTo('#internalAction', '500', this.scrollOptions)
    }
  }
}
</script>

<style lang="scss" scoped></style>
