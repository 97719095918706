import '@babel/polyfill'

import Vue from 'vue'
import PortalVue from 'portal-vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import { store } from './store'
import LoadScript from 'vue-plugin-load-script'
import AppConfig from './plugins/vue-appconfig'
import VueInputmask from './plugins/vue-inputmask'
import './style'

Vue.config.productionTip = false

Vue.use(LoadScript)
Vue.use(AppConfig)
Vue.use(VueInputmask)
Vue.use(PortalVue)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
