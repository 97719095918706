import bus from '@/bus'

const Emiter = store => {
  store.subscribe((mutation, state) => {
    mutation.ts = Date.now()
    bus.emit(mutation.type, mutation)
  })
}

export default Emiter
