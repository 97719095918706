<template>
  <div class="column is-4-desktop is-paddingless">
    <StepHeader :active="payment.step === 3" :step="3">
      {{ $t('payment.general.step-three') }}
    </StepHeader>
    <div class="step">
      <component
        v-if="widget"
        :payment="payment"
        :is="componentFile"
      ></component>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import StepHeader from '@/components/Payment/StepHeader'

export default {
  name: 'PaymentWidget',
  components: {
    StepHeader
  },
  data: () => {
    return {
      validWidgetComponents: ['Payon', 'PayNext', 'Debit']
    }
  },
  computed: {
    widget: get('payment/widget'),
    payment: get('payment'),
    isValidComponent() {
      return this.validWidgetComponents.includes(this.widget)
    },
    componentFile() {
      const component = this.isValidComponent
        ? `Payment/Widget/${this.widget}`
        : 'Undefined'
      return () => import(`@/components/${component}.vue`)
    }
  }
}
</script>

<style lang="scss" scoped></style>
