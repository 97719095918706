<template>
  <div class="container">
    <Modal :active="!!success" :header="false" @modalClosed="modalClose">
      <template v-slot:content>
        <div class="notification is-success">
          <button class="delete" @click="modalClose"></button>
          <h3 class="has-text-white">
            {{ $t('services.addressChange.thanks') }}
          </h3>
          {{ $t('services.addressChange.success') }}
        </div>
      </template>
    </Modal>
    <Modal :active="!!error" :header="false" @modalClosed="error = false">
      <template v-slot:content>
        <div class="notification is-danger">
          <button class="delete" @click="error = false"></button>
          {{ $t(`errors.${error}`) }}
        </div>
      </template>
    </Modal>
    <form method="POST" @submit.prevent="submitMessage">
      <div class="field">
        <Radio
          v-model="form.filenumber"
          name="filenumber"
          :label="$t('services.label.identifier')"
          :options="claimsFilenumbers"
          :validationState="validationState($v.form.filenumber)"
          required
          :i18n="false"
          @change.native="onFileChange"
        />
      </div>
      <div v-if="type === 'subsequentPresentation'">
        <div class="field">
          <Checkbox
            v-model="form.isChipcard"
            :label="$t('services.label.chipcard')"
          />
        </div>
        <div class="field">
          <Input
            v-if="form.isChipcard"
            :label="$t('services.label.chipcardnumber')"
            v-model="subsequentPresentation.chipcardnumber"
          />
          <Input
            v-else
            :label="$t('services.label.ticketnumber')"
            v-model="subsequentPresentation.ticketnumber"
          />
        </div>
      </div>
      <div class="field is-grouped">
        <Input
          :label="$t('form.labels.givenName')"
          v-model="form.personalData.givenName"
          :validationState="
            validationState($v.form.personalData.givenName, true)
          "
          @input.native="$v.form.personalData.givenName.$touch"
          required
        />
        <Input
          :label="$t('form.labels.surname')"
          v-model="form.personalData.surname"
          :validationState="validationState($v.form.personalData.surname, true)"
          @input.native="$v.form.personalData.surname.$touch"
          required
        />
        <Input
          :label="$t('form.labels.dayOfBirth')"
          v-model="dob"
          :validationState="
            validationState($v.form.personalData.dayOfBirth, true)
          "
          @input.native="$v.form.personalData.dayOfBirth.$touch"
          :mask="maskDob"
          required
        />
      </div>
      <div class="field is-grouped">
        <Input
          :label="$t('form.labels.streetAddress')"
          v-model="form.personalData.streetAddress"
          :validationState="
            validationState($v.form.personalData.streetAddress, true)
          "
          @input.native="$v.form.personalData.streetAddress.$touch"
          required
        />
        <Input
          :label="$t('form.labels.streetNumber')"
          v-model="form.personalData.streetNumber"
          :validationState="
            validationState($v.form.personalData.streetNumber, true)
          "
          @input.native="$v.form.personalData.streetNumber.$touch"
          required
        />
      </div>

      <div class="field is-grouped">
        <Input
          :label="$t('form.labels.zip')"
          v-model="form.personalData.zip"
          :validationState="validationState($v.form.personalData.zip, true)"
          @input.native="$v.form.personalData.zip.$touch"
          required
        />
        <Input
          :label="$t('form.labels.city')"
          v-model="form.personalData.city"
          :validationState="validationState($v.form.personalData.city, true)"
          @input.native="$v.form.personalData.city.$touch"
          required
        />
        <div class="field">
          <div class="control">
            <Label :label="$t('form.labels.country')" :required="true" />
            <div class="select is-empty">
              <Countries v-model="form.personalData.country"></Countries>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-grouped">
        <Input
          :label="$t('form.labels.email')"
          v-model="form.email"
          :validationState="validationState($v.form.email, true)"
          @input.native="$v.form.email.$touch"
          :postContent="$t('services.message.info.email')"
          required
        />
        <Input :label="$t('form.labels.phone')" v-model="form.phone" />
      </div>
      <div class="field is-grouped" v-if="hasMessage">
        <Textarea
          :label="$t('form.labels.sendMessage.message')"
          v-model="form.message"
          @blur="$v.form.message.$touch"
          :validationState="validationState($v.form.message, true)"
          required
        />
      </div>
      <div class="field" v-if="hasUpload">
        <Upload
          v-model="form.attachments"
          :options="uploadOptions"
          :label="$t('form.labels.sendMessage.files')"
          :success="!$v.form.attachments.$invalid"
          :disabled="!canUpload"
          :limit="uploadsLimit"
          @uploaded="onUploaded"
          @deleteUpload="onDeleteUpload"
          @blur="$v.form.attachments.$touch"
          :validationState="validationState($v.form.attachments, true)"
          :required="attachmentsRequired"
          :showIcon="attachmentsRequired"
        />
      </div>

      <div class="field is-grouped" v-if="type === 'arrangeDeferral'">
        <Date
          v-model="arrangeDeferral.date"
          validDates="notBeforeToday"
          validPeriod="2-month"
          :validation="$v.arrangeDeferral.date"
          :label="$t('form.labels.sendMessage.deferralDate')"
          required
        />
        <Input
          :label="$t('form.labels.amount')"
          v-model.lazy="arrangeDeferral.amount"
          inputType="money"
          :validationState="validationState($v.arrangeDeferral.amount)"
          required
        />
      </div>

      <div class="field is-grouped" v-if="type === 'arrangeInstalment'">
        <Date
          v-model="arrangeInstalment.date"
          validDates="notBeforeToday"
          :validation="$v.arrangeInstalment.date"
          :label="$t('form.labels.sendMessage.instalment')"
          required
        />
        <Input
          :label="$t('form.labels.amount')"
          v-model.lazy="arrangeInstalment.amount"
          inputType="money"
          :validationState="validationState($v.arrangeInstalment.amount)"
          required
        />
      </div>

      <p>{{ $t('form.labels.sendMessage.hint') }}</p>
      <p v-html="$t('form.labels.sendMessage.info')"></p>

      <Checkbox
        v-model="form.confirmForm"
        :label="$t('form.labels.confirmForm')"
        :validationState="validationState($v.form.confirmForm, true)"
        required
      />

      <Checkbox
        v-model="form.confirmDataPrivacy"
        :label="$t('form.labels.confirmDataPrivacy', { link: dataPrivacyLink })"
        :validationState="validationState($v.form.confirmDataPrivacy, true)"
        required
      />
      <div style="margin-top:3em">
        <button
          v-if="!$v.$invalid"
          class="button is-dark"
          @click.prevent="submitMessage"
        >
          {{ $t('form.submit') }}
        </button>
        <div v-else @click="validate" style="cursor: not-allowed">
          <button class="button is-dark" disabled style="pointer-events: none">
            {{ $t('form.submit') }}
          </button>
        </div>
      </div>

      <div class="is-size-6" v-if="hasUpload">
        {{ $t('form.description.submit') }}
      </div>
    </form>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import dayjs from 'dayjs'
import dottie from 'dottie'
import Modal from '@/components/Modal'
import {
  Checkbox,
  Countries,
  Date,
  Input,
  Label,
  Radio,
  Textarea,
  Upload
} from '@/components/Form'
import {
  required,
  email,
  sameAs,
  minLength,
  minValue
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { customValidationMixin } from '@/mixins/validation'
import { matomoMixin } from '@/mixins/matomo'
import { portalMixin } from '@/mixins/portal'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'SelfserviceActionSendMessage',
  mixins: [validationMixin, customValidationMixin, matomoMixin, portalMixin],
  components: {
    Modal,
    Checkbox,
    Countries,
    Date,
    Input,
    Label,
    Radio,
    Textarea,
    Upload
  },
  props: {
    type: String
  },
  validations() {
    const validation = {
      form: {
        filenumber: { required },
        confirmForm: { required, sameAs: sameAs(() => true) },
        confirmDataPrivacy: {
          required,
          sameAs: sameAs(() => true)
        },
        email: { required, email },
        personalData: {
          givenName: { required },
          surname: { required },
          dayOfBirth: { required },
          streetAddress: { required },
          streetNumber: { required },
          zip: { required },
          city: { required },
          country: { required }
        },
        message: {},
        attachments: {}
      }
    }

    if (this.hasMessage) validation.form.message = { required }

    if (this.type === 'subsequentPresentation') {
      // if (this.form.isChipcard)
      //   validation.subsequentPresentation = {
      //     chipcardnumber: { required }
      //   }
      // if (!this.form.isChipcard)
      //   validation.subsequentPresentation = {
      //     ticketnumber: { required }
      //   }
      validation.form.attachments = {
        required,
        minLength: minLength(1)
      }
    }

    if (this.type === 'arrangeDeferral') {
      validation.arrangeDeferral = {
        amount: { required, minValue: minValue(0.01) },
        date: { required }
      }
    }
    if (this.type === 'arrangeInstalment') {
      validation.arrangeInstalment = {
        amount: { required, minValue: minValue(5) },
        date: { required }
      }
    }

    return validation
  },
  computed: {
    attachmentsRequired() {
      return this.type === 'subsequentPresentation'
    },
    claim: sync('portal/claim'),
    claims: get('portal/claims'),
    address: get('portal/address'),
    currentAction: sync('portal/currentAction'),
    formtype: sync('message/formtype'),
    form: sync('message/general'),
    makeComplaint: sync('message/makeComplaint'),
    subsequentPresentation: sync('message/subsequentPresentation'),
    arrangeDeferral: sync('message/arrangeDeferral'),
    arrangeInstalment: sync('message/arrangeInstalment'),
    error: sync('message/error'),
    success: sync('message/success'),
    claimsFilenumbers() {
      return this.portalIdentifierList(this.claims, this.identifierType, false)
    },
    claimFilenumber() {
      return this.portalIdentifier(this.claim, this.identifierType)
    },
    identifierType() {
      return this.$appconfig.get('identifier.type', false)
    },
    maskDob() {
      return {
        alias: 'datetime',
        inputFormat: 'dd.mm.yyyy',
        placeholder: '__.__.____'
      }
    },
    dob: {
      get() {
        return dayjs(this.form.personalData.dayOfBirth, 'YYYY-MM-DD').format(
          'DD.MM.YYYY'
        )
      },
      set(value) {
        this.form.personalData.dayOfBirth = dayjs(value, 'DD.MM.YYYY').format(
          'YYYY-MM-DD'
        )
      }
    },
    dataPrivacyLink() {
      return this.$appconfig.get('links.dataPrivacy', '')
    },
    uploadOptions() {
      return this.$appconfig.get('upload.options', {})
    },
    uploadsLimit() {
      return (
        this.$appconfig.get('upload.options.restrictions.maxNumberOfFiles', 2) -
        this.form.attachments.length
      )
    },
    canUpload() {
      return this.uploadsLimit > 0
    },
    hasUpload() {
      return !['arrangeDeferral', 'arrangeInstalment'].includes(this.type)
    },
    hasMessage() {
      return !['arrangeDeferral', 'arrangeInstalment'].includes(this.type)
    },
    hasAmount() {
      return ['arrangeDeferral', 'arrangeInstalment'].includes(this.type)
    },
    instalmentDefault() {
      return dottie.get(
        this.claim,
        'agreementConditions.suggestedInstalmentAmount',
        5
      )
    }
  },
  watch: {
    claim: {
      handler(val) {
        this.form.filenumber = this.claimFilenumber
        if (this.type === 'arrangeDeferral') {
          this.arrangeDeferral.amount = this.claim.balance
        }
        if (this.type === 'arrangeInstalment') {
          this.arrangeInstalment.amount = this.instalmentDefault
        }
      },
      deep: true
    }
  },
  created() {
    this.resetState()
    this.form.filenumber = this.claimFilenumber
    if (this.type === 'arrangeDeferral')
      this.arrangeDeferral.amount = this.claim.balance
    if (this.type === 'arrangeInstalment') {
      this.arrangeInstalment.amount = this.instalmentDefault
    }

    this.formtype = this.type
    this.mapUserData({ user: this.address })
  },
  methods: {
    resetState: call('message/resetState'),
    sendMessage: call('message/sendMessage'),
    mapClaimData: call('message/mapClaimData'),
    mapUserData: call('message/mapUserData'),
    uploaded: call('message/addAttachement'),
    deleteUpload: call('message/deleteAttachement'),
    onUploaded(value) {
      this.uploaded(value)
    },
    onDeleteUpload(index) {
      this.deleteUpload(index)
    },
    submitMessage() {
      this.matomoButtonEvent('form_sent')
      this.sendMessage()
    },
    modalClose() {
      this.success = false
      this.currentAction = ''
    },
    onFileChange() {
      this.claim = this.getClaimByIdentifier(
        this.claims,
        this.form.filenumber,
        this.identifierType
      )
      if (this.type === 'arrangeDeferral') {
        this.arrangeDeferral.amount = this.claim.balance
      }
      if (this.type === 'arrangeInstalment') {
        this.arrangeInstalment.amount = this.instalmentDefault
      }
    },
    validate() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        VueScrollTo.scrollTo(
          this.$el.querySelector('input.is-danger', 'div.is-danger'),
          '500',
          {
            easing: 'ease-in',
            offset: -160
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
