<template>
  <Selfservice />
</template>

<script>
import Selfservice from '@/components/Selfservice'

export default {
  components: {
    Selfservice
  },
  created() {
    if (this.$route.path.match(/^\/selfservice/i))
      document.title = `${document.title} Selfservice Portal`
  }
}
</script>

<style lang="scss" scoped></style>
