<template>
  <div>
    <div class="container content">
      <Overview />
      <div id="internalAction"></div>
      <transition name="component-fade" mode="out-in">
        <component :is="internalComponent" />
      </transition>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import Overview from '@/components/Selfservice/Overview'
import Instalment from '@/components/Selfservice/Actions/Instalment'
import Deferral from '@/components/Selfservice/Actions/Deferral'
import Complaint from '@/components/Selfservice/Actions/Complaint'
import SubsequentPresentation from '@/components/Selfservice/Actions/SubsequentPresentation'
import AddressChange from '@/components/Selfservice/Actions/AddressChange'

export default {
  name: 'SelfserviceInternal',
  components: {
    Overview,
    Deferral,
    Instalment,
    Complaint,
    SubsequentPresentation,
    AddressChange
  },
  computed: {
    claims: get('portal/claims'),
    currentAction: get('portal/currentAction'),
    internalComponent() {
      let component = ''
      switch (this.currentAction) {
        case 'arrange_deferral':
          component = 'Deferral'
          break
        case 'arrange_instalment':
          component = 'Instalment'
          break
        case 'complaint':
          component = 'Complaint'
          break
        case 'subsequent_presentation':
          component = 'SubsequentPresentation'
          break
        case 'address_change':
          component = 'AddressChange'
          break
      }
      return component
    }
  },
  async created() {
    await this.getClaims({ refresh: true })
  },
  methods: {
    getClaims: call('portal/requestClaims')
  }
}
</script>

<style lang="scss" scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
