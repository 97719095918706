<template>
  <div class="section">
    <Video v-show="videoEnabled" />
    <Easylog />
    <router-view />
  </div>
</template>

<script>
import Video from '@/components/Video'
import Easylog from '@/components/Easylog'

export default {
  components: {
    Video,
    Easylog
  },
  props: {
    portalElement: {
      type: String,
      default: '#app'
    }
  },
  data() {
    return {}
  },
  computed: {
    videoEnabled() {
      return this.$appconfig.get('video_enabled', false)
    }
  }
}
</script>
