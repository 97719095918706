import payment from '@/api/payment'

import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    widgetApiUrl: '',
    tokenizationServiceUrl: '',
    securityToken: '',
    paymentToken: '',
    error: false,
    success: false
  }
}

// like: initial state
const state = getDefaultState()

// like: aggregates
const getters = {
  ...make.getters(state)
}

// like: events
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  ...make.mutations(state)
}

// like: commands
const actions = {
  resetState({ commit }) {
    commit('resetState')
  },

  async requestWidgetData({ commit }) {
    try {
      const response = await payment().get('widgetdata')
      commit('SET_SECURITY_TOKEN', response.data.securityToken)
      commit('SET_WIDGET_API_URL', response.data.widgetApiUrl)
      commit(
        'SET_TOKENIZATION_SERVICE_URL',
        response.data.tokenizationServiceUrl
      )
    } catch (err) {
      if (err.response.status === 400) {
        return commit('SET_ERROR', 'INVALID_PARAMS')
      }
      return commit('SET_ERROR', 'TECHNICALERROR')
    }
  },

  // TODO: REMOVE?
  setPaymentToken({ commit }, token) {
    commit('SET_PAYMENT_TOKEN', token)
  },

  async authorizePayment({ getters, commit }, payload) {
    try {
      const result = await payment().post('authorize', {
        amount: payload.amount,
        amountType: payload.amountType,
        reference: payload.identifier,
        method: payload.method,
        paymentToken: getters.paymentToken,
        returnTo: payload.returnURL
      })

      if (result.data.finished) {
        return commit('SET_SUCCESS', true)
      }
      if (result.data.redirectTo) {
        window.location = result.data.redirectTo
      }
    } catch (err) {
      if (err.response.status === 400) {
        return commit('SET_ERROR', 'INVALID_PARAMS')
      }
      return commit('SET_ERROR', 'TECHNICALERROR')
    }
  },

  async requestPaymentInfo({ commit }, transactionId) {
    try {
      const result = await payment().get(`info/${transactionId}`)

      const { status } = result.data

      switch (status) {
        case 'success':
          return commit('SET_SUCCESS', true)
        case 'cancelled':
          return commit('SET_ERROR', 'TRANSACTION_CANCELLED')
        default:
          return commit('SET_ERROR', 'TECHNICALERROR')
      }
    } catch (err) {
      if (err.response.status === 400) {
        return commit('SET_ERROR', 'INVALID_PARAMS')
      }
      return commit('SET_ERROR', 'TECHNICALERROR')
    }
  }
}

export default {
  // like: domain
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
