import portal from '@/api/portal'
import { make } from 'vuex-pathify'
import AppConfig from '@/appconfig'
import dottie from 'dottie'

const getDefaultState = () => {
  return {
    claim: {
      actions: []
    },
    claims: [],
    identifier: '',
    identifierType: AppConfig.get('identifier.type'),
    address: {},
    deferralDate: '',
    message: {},
    arrangeInstalment: {
      method: 'bank_transfer',
      amount: 0,
      currency: 'EUR',
      date: '',
      number: 0
    },
    currentAction: '',
    addressChangeSuccess: false,
    addressChangeError: false,
    arrangeDeferralSuccess: false,
    arrangeDeferralError: false,
    arrangeInstalmentSuccess: false,
    arrangeInstalmentError: false,
    sendMessageSuccess: false,
    sendMessageError: false,
    error: false
  }
}

const expiredToken = error => {
  if (dottie.get(error, 'response.data.errors')) {
    const expired = error.response.data.errors.filter(
      e => e.title === 'INVALID_OR_EXPIRED_SESSION'
    )
    return expired.length
  }
  return false
}

// like: initial state
const state = getDefaultState()

// like: aggregates
const getters = {
  ...make.getters(state),
  partialPaymentAllowed: state => {
    return state.claim.actions.includes('partial_payment')
  },
  instalmentAllowed: state => {
    return dottie.get(state.claim, 'agreement.type') === 'instalment'
  },
  instalmentAmount: state => {
    return dottie.get(state.claim, 'agreement.instalmentAmount')
  },
  minPartialAmount: state => {
    return dottie.get(state.claim, 'agreementConditions.minimalPartialAmount')
  },
  fullAmount: state => {
    return dottie.get(state.claim, 'balance')
  },
  claimIdentifier: state => {
    if (state.identifierType === 'customernumber') {
      return dottie.get(state.claim, 'claimDetails.customerNumber', 'n/a')
    }
    return dottie.get(state.claim, 'filenumber', 'n/a')
  },
  fullName: state => {
    return `${state.address.givenName} ${state.address.surname} `
  },
  maximumNumberOfInstalments: state => {
    return dottie.get(
      state.claim,
      'agreementConditions.maximumNumberOfInstalments',
      'n/a'
    )
  },
  minimalInstalmentAmount: state => {
    return dottie.get(
      state.claim,
      'agreementConditions.minimalInstalmentAmount',
      'n/a'
    )
  },
  suggestedInstalmentAmount: state => {
    return dottie.get(
      state.claim,
      'agreementConditions.suggestedInstalmentAmount',
      'n/a'
    )
  },
  instalmentAgreementFee: state => {
    return dottie.get(
      state.claim,
      'agreementConditions.instalmentAgreementFee',
      'n/a'
    )
  },
  latestDeferralDate: state => {
    return dottie.get(
      state.claim,
      'agreementConditions.latestDeferralDate',
      'n/a'
    )
  }
}

// like: events
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  resetAuthUserData(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, { claims: [], address: {} })
  },
  addCustomerNumberIdentifier(state) {
    dottie.set(state.claim, 'claimDetails.customerNumber', state.identifier)
  },
  handleRequestClaim(state, payload) {
    // handle this in own mutation to get specific event
    state.claim = payload.data
    state.error = false
  },
  handleSuccess(state, payload) {
    state[payload.prop] = payload.data
    state.error = false
  },
  handleError(state, payload) {
    let name = 'TECHNICALERROR'
    if (payload.error.response.status === 400) {
      name = 'INVALID_PARAMS'
      if (expiredToken(payload.error)) localStorage.removeItem('portalToken')
    }
    const errorKey = payload.key || 'error'
    state[errorKey] = name
  },
  ...make.mutations(state)
}

// like: commands
const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
  resetAuthUserData({ commit }) {
    commit('resetAuthUserData')
  },
  setEasylogClaim({ commit }, payload) {
    if (payload.claim) {
      commit('SET_CLAIM', payload.claim)
    }
  },
  async requestClaim({ commit, state }, payload) {
    try {
      const response = await portal().get(
        `claim/${state.identifier}${payload.refresh ? '/refresh' : ''}`
      )
      commit('handleSuccess', { prop: 'claim', data: response.data })
      if (state.identifierType === 'customernumber') {
        commit('addCustomerNumberIdentifier')
      }
    } catch (error) {
      commit('handleError', { error: error })
    }
  },
  async requestClaims({ commit, rootState, dispatch }, payload) {
    try {
      const response = await portal(rootState.auth.token).get(
        `claims${payload.refresh ? '/refresh' : ''}`
      )
      commit('handleSuccess', { prop: 'claims', data: response.data })
    } catch (error) {
      commit('handleError', { error: error })
      if (expiredToken(error))
        dispatch('auth/deleteToken', null, { root: true })
    }
  },
  async requestAddress({ commit, rootState, dispatch }, payload) {
    try {
      const response = await portal(rootState.auth.token).get(
        `address${payload.refresh ? '/refresh' : ''}`
      )
      commit('handleSuccess', { prop: 'address', data: response.data })
    } catch (error) {
      commit('handleError', { error: error })
      if (expiredToken(error))
        dispatch('auth/deleteToken', null, { root: true })
    }
  },
  async requestAddressChange({ commit, rootState, dispatch }) {
    try {
      await portal(rootState.auth.token).post('address', state.address)
      commit('SET_ADDRESS_CHANGE_SUCCESS', true)
    } catch (error) {
      commit('handleError', { key: 'addressChangeError', error: error })
      if (expiredToken(error))
        dispatch('auth/deleteToken', null, { root: true })
    }
  },
  async arrangeDeferral({ commit, rootState, dispatch }, payload) {
    try {
      await portal(rootState.auth.token).post(
        `arrange_deferral/${payload.identifier}`,
        {
          date: state.deferralDate
        }
      )
      commit('SET_ARRANGE_DEFERRAL_SUCCESS', true)
    } catch (error) {
      commit('handleError', { key: 'arrangeDeferralError', error: error })
      if (expiredToken(error))
        dispatch('auth/deleteToken', null, { root: true })
    }
  },
  async arrangeInstalment({ commit, rootState, dispatch }, payload) {
    try {
      await portal(rootState.auth.token).post(
        `arrange_instalment/${payload.identifier}`,
        {
          method: state.arrangeInstalment.method,
          amount: state.arrangeInstalment.amount,
          currency: state.arrangeInstalment.currency,
          date: state.arrangeInstalment.date,
          number: state.arrangeInstalment.number
        }
      )
      commit('SET_ARRANGE_INSTALMENT_SUCCESS', true)
    } catch (error) {
      commit('handleError', { key: 'arrangeInstalmentError', error: error })
      if (expiredToken(error))
        dispatch('auth/deleteToken', null, { root: true })
    }
  }
}

export default {
  // like: domain
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
