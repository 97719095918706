import payment from '@/api/payment'

import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    url: '',
    error: false,
    success: false
  }
}

// like: initial state
const state = getDefaultState()

// like: aggregates
const getters = {
  ...make.getters(state)
}

// like: events
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  ...make.mutations(state)
}

// like: commands
const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
  async requestPayonPaymentUrl({ commit }, payload) {
    try {
      const response = await payment().post('start', {
        filenumber: payload.identifier,
        amount: payload.amount,
        amountType: payload.amountType,
        currency: payload.currency,
        method: payload.selectedMethod
      })
      commit('SET_URL', response.data.url)
    } catch (err) {
      if (err.response.status === 400) {
        return commit('SET_ERROR', 'INVALID_PARAMS')
      }
      return commit('SET_ERROR', 'TECHNICALERROR')
    }
  },
  async finishPayment({ commit }, payload) {
    try {
      await payment().post('finish', {
        resourcePath: payload.resourcePath
      })
      commit('SET_SUCCESS', true)
    } catch (err) {
      return commit('SET_ERROR', err.response.data.errors[0])
    }
  }
}

export default {
  // like: domain
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
