/**
 * The file enables `@/store/modules/index.js` to import all vuex modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */

const files = require.context('.', false, /\.js$/)
const contactFiles = require.context('./contact', false, /\.js$/)
const modules = {}

files.keys().forEach(key => {
  if (key === './index.js') return
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

// Load contact store separately for each site since it's not generic
contactFiles.keys().forEach(key => {
  if (key === './index.js') return
  if (process.env.VUE_APP_SITE === key.replace(/(\.\/|\.js)/g, '')) {
    modules['contact'] = contactFiles(key).default
  }
})

export default modules
