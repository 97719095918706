import contact from '@/api/contact'
import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    form: {
      filenumber: '',
      company: '',
      confirmForm: false,
      confirmDataPrivacy: false,
      sender: 'self',
      email: '',
      phone: '',
      personalData: {
        givenName: '',
        surname: '',
        dayOfBirth: '',
        streetAddress: '',
        streetNumber: '',
        zip: '',
        city: '',
        country: 'DE'
      },
      message: '',
      attachments: []
    },
    success: false,
    error: false
  }
}

// const dateConvert = date => {
//   const d = date.split('.')
//   return [d[2], d[1], d[0]].join('-')
// }

// like: initial state
const state = getDefaultState()

// like: aggregates
const getters = {
  ...make.getters(state)
}

// like: events
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  addAttachment(state, payload) {
    if (!payload.id && !payload.originalFilename) return
    const attachment = {
      id: payload.id,
      originalFilename: payload.originalFilename
    }
    state.form.attachments.push(attachment)
  },
  deleteAttachment(state, index) {
    state.form.attachments.splice(index, 1)
  },
  formSent(state) {
    state.form.sent = true
  },
  ...make.mutations(state)
}

// like: commands
const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
  addAttachement({ commit }, payload) {
    commit('addAttachment', payload)
  },
  deleteAttachement({ commit }, index) {
    commit('deleteAttachment', index)
  },
  async sendContact({ commit, state }) {
    const data = {}
    try {
      await contact().post('message', data)
      return commit('formSent')
    } catch (err) {
      if (err.response.status === 400) {
        return commit('SET_ERROR', 'INVALID_PARAMS')
      }
      return commit('SET_ERROR', 'TECHNICALERROR')
    }
  }
}

export default {
  // like: domain
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
