<template>
  <div class="field">
    <div class="control">
      <Label v-if="label" :label="label" :required="required" />
      <div class="select is-empty">
        <select @change="editValue($event.target.value)" :class="className">
          <option
            v-for="(option, index) in options"
            :value="getValue(option)"
            :selected="isSelected(option)"
            :key="index"
          >
            {{ getOption(option) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Label from '@/components/Form/Label'

export default {
  name: 'Select',
  components: {
    Label
  },
  props: {
    label: String,
    value: [String, Number],
    options: Array,
    className: String,
    required: Boolean,
    default: String,
    translateOptions: Boolean,
    translateValues: Boolean
  },
  created() {
    if (!this.value && this.default) this.editValue(this.default)
  },
  methods: {
    getValue(option) {
      return this.translateValues ? this.$t(`form.options.${option}`) : option
    },
    getOption(option) {
      return this.translateOptions ? this.$t(`form.options.${option}`) : option
    },
    isSelected(option) {
      return this.getValue(option) === this.value
    },
    editValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped></style>
