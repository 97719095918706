<template>
  <!-- Step 1 -->
  <div class="column is-3-desktop is-paddingless">
    <StepHeader :active="step === 1" :step="1">
      {{ $t('payment.general.step-one') }}
    </StepHeader>

    <div class="step buttons step-1">
      <PaymentButton
        @click.native="setFullPayment"
        :active="amountType === 'full'"
      >
        {{ $t('payment.first-step.full') }}
      </PaymentButton>

      <PaymentButton
        v-if="partialPaymentAllowed"
        @click.native="editPartialPayment"
        :active="amountType === 'partial'"
      >
        {{ $t('payment.first-step.partial') }}
      </PaymentButton>

      <PaymentButton
        v-if="instalmentAllowed"
        @click.native="setInstalment"
        :active="amountType === 'instalment'"
      >
        {{ $t('payment.first-step.instalment') }}
      </PaymentButton>

      <Input
        :label="$t('payment.general.amount')"
        v-model.lazy="displayAmount"
        inputType="money"
        :disabled="!edit"
        @blur="$v.displayAmount.$touch()"
        :validationState="validationState($v.displayAmount)"
        :errorMessage="errorMessage('displayAmount')"
      />
      <a
        href="#"
        class="button"
        v-show="edit"
        :disabled="$v.displayAmount.$invalid"
        @click="setAmount"
      >
        {{ $t('payment.first-step.submit') }}
      </a>
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify'
import StepHeader from '@/components/Payment/StepHeader'
import PaymentButton from '@/components/Payment/Button'
import Input from '@/components/Form/Input'
import { validationMixin } from 'vuelidate'
import { customValidationMixin } from '@/mixins/validation'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'

export default {
  name: 'PaymentAmount',
  mixins: [validationMixin, customValidationMixin],
  components: {
    StepHeader,
    PaymentButton,
    Input
  },
  validations() {
    return {
      displayAmount: {
        required,
        minValue: minValue(this.minPartialAmount),
        maxValue: maxValue(this.fullAmount)
      }
    }
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '€',
        precision: 2,
        masked: false
      },

      edit: false,
      error: false
    }
  },
  computed: {
    claim: get('portal/claim'),
    step: sync('payment/step'),
    amount: sync('payment/amount'),
    displayAmount: sync('payment/displayAmount'),
    amountType: sync('payment/amountType'),
    currency: get('payment/currency'),
    partialPaymentAllowed: get('portal/partialPaymentAllowed'),
    instalmentAllowed: get('portal/instalmentAllowed'),
    instalmentAmount: get('portal/instalmentAmount'),
    minPartialAmount: get('portal/minPartialAmount'),
    fullAmount: get('portal/fullAmount')
  },
  methods: {
    onFocus() {},
    setFullPayment() {
      this.amountType = 'full'
      this.displayAmount = this.fullAmount
      this.edit = false
      this.setAmount()
    },
    editPartialPayment() {
      if (this.partialPaymentAllowed && this.minPartialAmount) {
        this.step = 1
        this.amountType = 'partial'
        this.edit = true
      }
    },
    setInstalment() {
      if (this.instalmentAllowed) {
        this.amountType = 'instalment'
        this.displayAmount = this.instalmentAmount
        this.edit = false
        this.setAmount()
      }
    },
    setAmount() {
      if (this.$v.displayAmount.$invalid) return
      if (this.displayAmount === this.fullAmount) {
        this.edit = false
        this.amountType = 'full'
      }
      this.amount = this.displayAmount
      this.step = 2
    },
    errorMessage(key) {
      let message
      if (key === 'displayAmount') {
        message = this.$t('form.validations.amountError', {
          min: new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.minPartialAmount),
          max: new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.fullAmount)
        })
      }
      return this.$v[key].$dirty && this.$v[key].$invalid ? message : ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
