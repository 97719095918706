<template>
  <div class="field">
    <label class="label">
      {{ label }}
      <span v-if="required">*</span>
      <check-circle-icon
        v-if="validationState === 'success'"
        size="1x"
        class="check-circle-icon"
      ></check-circle-icon>
      <circle-icon
        v-else
        size="1x"
        class="circle-icon"
        :class="validationState === 'invalid' ? 'is-danger' : ''"
      ></circle-icon>
    </label>
    <div class="control">
      <label
        class="radio"
        :class="horizontal ? '' : 'is-block'"
        v-for="(option, index) in options"
        :key="index"
      >
        <input type="radio" v-model="inputVal" :name="name" :value="option" />
        <span class="check"></span>
        <span class="control-label">{{ translate(option) }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import { CircleIcon, CheckCircleIcon } from 'vue-feather-icons'

export default {
  components: { CircleIcon, CheckCircleIcon },
  props: {
    label: {
      type: String
    },
    options: {
      type: Array
    },
    value: {
      type: String
    },
    name: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    validationState: {
      type: String
    },
    validated: {
      type: Boolean,
      default: true
    },
    horizontal: {
      type: Boolean
    },
    i18n: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    translate(key) {
      if (!this.i18n) return key
      return this.$t('form.labels.' + key)
    }
  }
}
</script>

<style lang="scss" scoped>
.check-icon {
  color: green;
  margin: 0 6px;
}
</style>
