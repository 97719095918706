import Vue from 'vue'
import VueI18n from 'vue-i18n'

import enLocale from './locales/en.yaml'
import deLocale from './locales/de.yaml'
import appConfig from '@/appconfig'
import merge from 'deepmerge'

Vue.use(VueI18n)

const messages = {
  en: merge(enLocale, appConfig.get('i18n.en', {})),
  de: merge(deLocale, appConfig.get('i18n.de', {}))
}

const i18n = new VueI18n({
  locale: appConfig.get('i18n.default', 'de'),
  fallbackLocale: appConfig.get('i18n.fallbackLocale', 'de'),
  silentTranslationWarn: true,
  messages
})

export default i18n
