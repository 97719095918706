<template>
  <transition v-if="active" name="fade">
    <div class="modal" :class="{ 'is-active': active }">
      <div
        class="modal-background"
        @click.prevent="$emit('modalClosed', false)"
      ></div>
      <div class="modal-center">
        <transition appear name="slideDown">
          <div class="modal-card">
            <header class="modal-card-head" v-show="header">
              <h2 class="modal-card-title" v-show="title">{{ title }}</h2>
              <button
                class="delete"
                aria-label="close"
                @click.prevent="$emit('modalClosed', false)"
              ></button>
            </header>
            <section class="modal-card-content">
              <slot name="content"></slot>
            </section>
            <footer class="modal-card-foot" v-if="footer">
              <slot name="footer"></slot>
            </footer>
          </div>
        </transition>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click.prevent="$emit('modalClosed', false)"
      ></button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    showTooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showContent: false
    }
  },
  computed: {
    tooltip() {
      return true
    }
  }
}
</script>

<style lang="scss" scoped></style>
