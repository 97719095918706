<template>
  <!-- Step 2 -->
  <div class="column is-5-desktop is-paddingless">
    <StepHeader :active="step === 2" :step="2">
      {{ $t('payment.general.step-two') }}
    </StepHeader>
    <div class="columns step step-2">
      <div class="payment-methods column is-5">
        <PaymentMethod
          v-for="(method, index) in availableMethods"
          :key="index"
          v-model="selectedMethod"
          :realValue="method.name"
          :disabled="methodDisabled(method.name)"
        />
      </div>
      <div class="payment-info column is-7">
        <table>
          <tr>
            <td class="is-borderless has-text-weight-bold">
              {{ $t('payment.general.total-amount') }}:
            </td>
            <td class="is-borderless has-text-right has-text-weight-bold">
              <MoneyFormat
                :value="amount"
                locale="de"
                :currency-code="currency"
                :subunit-value="true"
                :hide-subunits="false"
                align="right"
              ></MoneyFormat>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify'
import StepHeader from '@/components/Payment/StepHeader'
import PaymentMethod from '@/components/Payment/Method'
import MoneyFormat from '@/components/MoneyFormat'

export default {
  name: 'PaymentSelect',
  components: {
    StepHeader,
    PaymentMethod,
    MoneyFormat
  },
  computed: {
    step: sync('payment/step'),
    selectedMethod: sync('payment/selectedMethod'),
    availableMethods: sync('payment/availableMethods'),
    amount: get('payment/amount'),
    currency: get('payment/currency')
  },
  watch: {
    amount() {
      this.resetMethod()
    }
  },
  methods: {
    async setMethod(method) {
      this.selectedMethod = method
      this.step = 3
    },
    methodDisabled(method) {
      return this.step !== 2 && this.selectedMethod !== method
    },
    resetMethod() {
      this.selectedMethod = ''
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
</style>
