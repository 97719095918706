import dottie from 'dottie'

const matomoEventNames = {
  pay: 'payment-chosen',
  subsequent_presentation: 'Subsequent-presentation-chosen',
  complaint: 'EBE-complaint-chosen',
  arrange_deferral: 'one-off-payment-chosen',
  arrange_instalment: 'Arrange-payment-instalments-chosen',
  address_change: 'change-of-adress',
  sofort: 'sofort-selected',
  giropay: 'giropay-selected',
  debit: 'debit-selected',
  form_sent: 'form-sent'
}

export const matomoMixin = {
  methods: {
    matomoButtonEvent(name) {
      const eventName = dottie.get(matomoEventNames, name, name)
      if (window._paq) window._paq.push(['trackEvent', eventName, 'Click'])
    }
  }
}
