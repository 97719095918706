import Vue from 'vue'
import Router from 'vue-router'
import i18n from './i18n'
import vbclass from 'vue-body-class'
import appConfig from './appconfig'

import Layout from './views/Layout.vue'
import Home from './views/Home.vue'
import Selfservice from './views/Selfservice.vue'
import Login from './views/Login.vue'

const VueRouter = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/:lang?/selfservice/test',
    //   name: 'test',
    //   meta: {
    //     bodyClass: 'app'
    //   },
    //   component: () => import('./views/Test.vue')
    // },
    {
      path: '/:lang?/selfservice/contact/:option?',
      name: 'contact',
      meta: {
        bodyClass: 'app'
      },
      component: () => import('./views/Contact.vue'),
      props: {
        portalElement: '#dialog'
      }
    },
    {
      path: '/:lang?/selfservice/easylog',
      redirect: { name: 'home' }
    },
    {
      path: '/:lang?/selfservice',
      name: 'selfservice',
      meta: {
        bodyClass: 'app'
      },
      redirect: { name: 'home' },
      component: Layout,
      props: {
        portalElement: '#dialog'
      },
      children: [
        {
          path: 'login',
          name: 'selfserviceLogin',
          component: Login
        },
        {
          path: ':id/:accesstype?',
          name: 'selfserviceClaim',
          component: Selfservice,
          children: [
            {
              path: 'payon/finish',
              name: 'payonFinish',
              component: Selfservice,
              meta: {
                bodyClass: 'app',
                modal: true,
                provider: 'Payon'
              }
            },
            {
              path: 'paynext/finish',
              name: 'paynextFinish',
              component: Selfservice,
              meta: {
                bodyClass: 'app',
                modal: true,
                provider: 'PayNext'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/:lang?',
      name: 'home',
      component: Home
    }
  ]
})

// use beforeEach route guard to set the language
VueRouter.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  const availableLanguages = appConfig.get('i18n.locales', [])
  let language = availableLanguages.includes(to.params.lang)
    ? to.params.lang
    : false
  if (!language) {
    language = appConfig.get('i18n.fallBackLocale', 'de')
  }
  i18n.locale = language
  next()
})

export default VueRouter

Vue.use(Router)
Vue.use(vbclass, VueRouter)
