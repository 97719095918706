<template>
  <span class="validation-icon icon is-small is-right">
    <check-circle-icon
      v-show="state === 'success'"
      size="1x"
      class="check-circle-icon is-success"
    ></check-circle-icon>
    <alert-circle-icon
      v-show="state === 'invalid'"
      size="1x"
      class="alert-circle-icon is-danger"
    ></alert-circle-icon>
  </span>
</template>

<script>
import { AlertCircleIcon, CheckCircleIcon } from 'vue-feather-icons'

export default {
  components: { AlertCircleIcon, CheckCircleIcon },
  props: {
    state: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.check-circle-icon.is-success {
  color: green;
}
.alert-circle-icon.is-danger {
  color: red;
}
</style>
