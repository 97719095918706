<template>
  <button
    class="button is-fullwidth is-marginless has-margin-bottom-15"
    :class="{ [activeClass]: active }"
  >
    <slot></slot>
    <check-circle-icon
      v-if="active"
      size="1x"
      class="check-circle-icon"
    ></check-circle-icon>
  </button>
</template>

<script>
import { CheckCircleIcon } from 'vue-feather-icons'
export default {
  name: 'PaymentButton',
  components: {
    CheckCircleIcon
  },
  props: {
    active: {
      type: Boolean
    }
  },
  data() {
    return {
      activeClass: 'is-primary'
    }
  }
}
</script>

<style lang="scss" scoped></style>
