<template>
  <div :class="formGroup">
    <Label :label="label" :required="required" />
    <div class="control is-clearfix input-wrap">
      <date-picker
        v-model="inputVal"
        valueType="format"
        :format="format"
        :lang="lang"
        :disabled-date="disabledDate"
        :input-class="inputClass"
      ></date-picker>
      <StateIcon v-show="showIcons" :state="state" />
    </div>
    <ErrorMessage v-if="validation" :message="error" :context="name" />
  </div>
</template>

<script>
import i18n from '@/i18n'
import dayjs from 'dayjs'
import { customValidationMixin } from '@/mixins/validation'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'
import 'vue2-datepicker/locale/en'

import Label from '@/components/Form/Label'
import ErrorMessage from '@/components/Form/ErrorMessage'
import StateIcon from '@/components/Icons/StateIcon'

export default {
  components: { DatePicker, Label, ErrorMessage, StateIcon },
  mixins: [customValidationMixin],
  props: {
    label: String,
    name: String,
    value: String,
    format: {
      type: String,
      default: 'DD.MM.YYYY'
    },
    validation: Object,
    required: Boolean,
    error: String,
    validDates: String,
    validPeriod: {
      type: String,
      default: ''
    },
    componentOptions: Object
  },
  computed: {
    inputVal: {
      get: function() {
        return dayjs(this.value).format(this.format)
      },
      set: function(value) {
        if (value)
          this.$emit('input', dayjs(value, this.format).format('YYYY-MM-DD'))
      }
    },
    state() {
      return this.validationState(this.validation)
    },
    showIcons() {
      if (!this.required) return false
      return this.$appconfig.get('forms.validationIcons', false)
    },
    lang() {
      return i18n.locale
    },
    formGroup() {
      return this.$appconfig.get('forms.style.formGroup', ['field'])
    },
    inputClass() {
      let inputClass = ['input', 'is-rounded']
      if (this.required) inputClass.push('required')
      if (this.state === 'invalid') inputClass.push('is-danger')
      if (this.state === 'success') inputClass.push('is-success')
      return inputClass
    },
    thresholdDayFuture() {
      if (!this.period) return new Date()
      return dayjs().add(this.period[0], this.period[1])
    },
    thresholdDayPast() {
      if (!this.period) return new Date()
      return dayjs().subtract(this.period[0], this.period[1])
    },
    period() {
      if (!this.validPeriod.match(/^\d+-(day|month|year)$/i)) return false
      return this.validPeriod.split('-')
    }
  },
  methods: {
    disabledDate(date) {
      let disabledType = ''
      const today = new Date()
      if (this.validDates === 'notBeforeToday') {
        if (this.period) {
          return date < today || date > this.thresholdDayFuture
        }
        return date < today
      }
      if (this.validDates === 'notAfterToday') {
        if (this.period) {
          return date > today || date < this.thresholdDayPast
        }
        return date > today
      }

      return disabledType
    }
  }
}
</script>

<style lang="scss" scoped>
.select-wrap {
  display: flex;
  align-items: center;
}
.validation-icon ::v-deep {
  margin-left: 0.5rem;
}
</style>
