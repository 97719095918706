import { render, staticRenderFns } from "./Complaint.vue?vue&type=template&id=1d1c75b0&scoped=true&"
import script from "./Complaint.vue?vue&type=script&lang=js&"
export * from "./Complaint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d1c75b0",
  null
  
)

export default component.exports