import portal from '@/api/portal'
import { make } from 'vuex-pathify'
import dottie from 'dottie'

const getDefaultState = () => {
  return {
    token: localStorage.getItem('portalToken') || '',
    persistToken: false,
    user: {},
    credentials: {
      key: '',
      pw: ''
    },
    firstname: '',
    lastname: '',
    dob: '',
    filenumber: '',
    customernumber: '',
    error: false
  }
}

// like: initial state
const state = getDefaultState()

// like: aggregates
const getters = {
  isAuthenticated: state => !!state.token,
  ...make.getters(state)
}

// like: events
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  handleSuccess(state, payload) {
    if (state.persistToken) {
      localStorage.setItem('portalToken', payload.data.token)
    }
    state.token = payload.data.token
    state.error = false
  },
  handleError(state, error) {
    let name = 'TECHNICALERROR'
    if (error.response.status === 400) {
      name = 'INVALID_PARAMS'
      if (error.response.data.errors.length) {
        const tokenError = error.response.data.errors.filter(
          e => e.title === 'INVALID_OR_EXPIRED_SESSION'
        )
        if (tokenError) localStorage.removeItem('portalToken')
      }
    }
    state.error = name
    state.token = ''
  },
  handleLogout(state) {
    state.token = ''
    localStorage.removeItem('portalToken')
  },
  ...make.mutations(state)
}

// like: commands
const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
  async login({ commit, state }) {
    try {
      const data = {
        [state.credentials.key]: dottie.get(
          state,
          state.credentials.key,
          false
        ),
        [state.credentials.pw]: dottie.get(state, state.credentials.pw, false)
      }
      if (state.persistToken) data['remember'] = true
      const response = await portal().post('login', data)
      return commit('handleSuccess', { data: response.data })
    } catch (error) {
      return commit('handleError', error)
    }
  },
  async logout({ commit, state }) {
    await portal(state.token).post('logout')
    commit('handleLogout')
  },
  deleteToken({ commit }) {
    commit('SET_TOKEN', '')
  }
}

export default {
  // like: domain
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
