export const customValidationMixin = {
  methods: {
    validationState(validation, ignoreDirty) {
      if (!validation) return
      if (!ignoreDirty && !validation.$dirty && validation.$invalid)
        return 'initial'
      if (validation.$invalid) return 'invalid'
      return 'success'
    }
  }
}
