import contact from '@/api/contact'
import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    form: {
      sent: false,
      identifier: '',
      company: '',
      personalData: {
        givenName: '',
        surname: '',
        dayOfBirth: ''
      },
      senderData: {
        givenName: '',
        surname: '',
        streetAddress: '',
        streetNumber: '',
        zip: '',
        city: '',
        country: 'DE'
      },
      sender: 'self',
      email: '',
      phone: '',
      inquiry: {
        selected: false,
        calculation: false,
        paymentConfirmation: false,
        proposePayment: {
          enabled: false,
          type: 'full',
          date: '',
          amount: '0',
          currency: 'EUR'
        },
        sendMessage: {
          enabled: false,
          attachments: []
        },
        changeBankAccount: {
          enabled: false,
          iban: '',
          bankCode: '',
          bankName: '',
          bankLocation: ''
        },
        changeAddress: {
          enabled: false,
          streetAddress: '',
          streetNumber: '',
          zip: '',
          city: '',
          country: 'DE',
          phone: ''
        }
      },
      confirmForm: false,
      confirmDataPrivacy: false
    },
    error: false
  }
}

const dateConvert = date => {
  const d = date.split('.')
  return [d[2], d[1], d[0]].join('-')
}

const dateDE = date => {
  return new Date(date).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
}

// like: initial state
const state = getDefaultState()

// like: aggregates
const getters = {
  overview(state) {
    let overview = [
      {
        label: 'form.labels.sender',
        value: state.form.sender,
        translate: true
      }
    ]

    if (state.form.sender !== 'self') {
      overview = overview.concat([
        { header: 'form.contactData', value: null },
        {
          label: 'form.labels.givenName',
          value: state.form.senderData.givenName
        },
        { label: 'form.labels.surname', value: state.form.senderData.surname },
        {
          label: 'form.labels.streetAddress',
          value: state.form.senderData.streetAddress
        },
        {
          label: 'form.labels.streetNumber',
          value: state.form.senderData.streetNumber
        },
        { label: 'form.labels.zip', value: state.form.senderData.zip },
        { label: 'form.labels.city', value: state.form.senderData.city },
        { label: 'form.labels.country', value: state.form.senderData.country },
        { label: 'form.labels.email', value: state.form.email },
        { label: 'form.labels.phone', value: state.form.phone }
      ])
    }

    overview = overview.concat([
      { header: 'form.debtorData' },
      { label: 'form.labels.identifier', value: state.form.identifier },
      { label: 'form.labels.company', value: state.form.company },
      {
        label: 'form.labels.givenName',
        value: state.form.personalData.givenName
      },
      { label: 'form.labels.surname', value: state.form.personalData.surname },
      {
        label: 'form.labels.dayOfBirth',
        value: dateDE(state.form.personalData.dayOfBirth)
      }
    ])

    if (state.form.sender === 'self') {
      overview = overview.concat([
        { label: 'form.labels.email', value: state.form.email },
        { label: 'form.labels.phone', value: state.form.phone }
      ])
    }

    overview = overview.concat([{ header: 'form.labels.inquiry' }])

    if (state.form.inquiry.calculation) {
      overview = overview.concat([
        {
          label: 'form.labels.requestCalculation',
          check: true
        }
      ])
    }
    if (state.form.inquiry.paymentConfirmation) {
      overview = overview.concat([
        {
          label: 'form.labels.requestPaymentConfirmation',
          check: true
        }
      ])
    }
    if (state.form.inquiry.proposePayment.enabled) {
      overview = overview.concat([
        { label: 'form.labels.proposePayment.title', check: true },
        {
          label: 'form.labels.proposePayment.type',
          value: state.form.inquiry.proposePayment.type,
          translate: true,
          indent: true
        },
        {
          label: `form.labels.proposePayment.date-${state.form.inquiry.proposePayment.type}`,
          value: state.form.inquiry.proposePayment.date,
          indent: true
        },
        {
          label: 'form.labels.proposePayment.amount',
          value: `${state.form.inquiry.proposePayment.amount} ${state.form.inquiry.proposePayment.currency}`,
          indent: true
        }
      ])
    }
    if (state.form.inquiry.sendMessage.enabled) {
      const sendMessage = [
        { label: 'form.labels.sendMessage.title', check: true },
        {
          label: 'form.labels.sendMessage.message',
          value: state.form.inquiry.sendMessage.message,
          indent: true
        }
      ]
      state.form.inquiry.sendMessage.attachments.forEach((a, index) => {
        sendMessage.push({
          label: index === 0 ? 'form.labels.fileupload' : '',
          value: a.originalFilename,
          indent: true
        })
      })
      overview = overview.concat(sendMessage)
    }

    if (state.form.inquiry.changeBankAccount.enabled) {
      overview = overview.concat([
        { label: 'form.labels.changeBankAccount.title', check: true },
        {
          label: 'form.labels.changeBankAccount.iban',
          value: state.form.inquiry.changeBankAccount.iban,
          indent: true
        },
        {
          label: 'form.labels.changeBankAccount.bankCode',
          value: state.form.inquiry.changeBankAccount.bankCode,
          indent: true
        },
        {
          label: 'form.labels.changeBankAccount.bankName',
          value: state.form.inquiry.changeBankAccount.bankName,
          indent: true
        },
        {
          label: 'form.labels.changeBankAccount.bankLocation',
          value: state.form.inquiry.changeBankAccount.bankLocation,
          indent: true
        }
      ])
    }
    if (state.form.inquiry.changeAddress.enabled) {
      overview = overview.concat([
        { label: 'form.labels.changeAddress.title', check: true },
        {
          label: 'form.labels.changeAddress.streetAddress',
          value: state.form.inquiry.changeAddress.streetAddress,
          indent: true
        },
        {
          label: 'form.labels.changeAddress.streetNumber',
          value: state.form.inquiry.changeAddress.streetNumber,
          indent: true
        },
        {
          label: 'form.labels.changeAddress.zip',
          value: state.form.inquiry.changeAddress.zip,
          indent: true
        },
        {
          label: 'form.labels.changeAddress.city',
          value: state.form.inquiry.changeAddress.city,
          indent: true
        },
        {
          label: 'form.labels.changeAddress.country',
          value: state.form.inquiry.changeAddress.country,
          indent: true
        },
        {
          label: 'form.labels.changeAddress.phone',
          value: state.form.inquiry.changeAddress.phone,
          indent: true
        }
      ])
    }

    overview = overview.concat([
      {
        label: 'form.labels.confirmForm',
        value: state.form.confirmForm,
        translate: true
      },
      {
        label: 'form.labels.confirmDataPrivacyShort',
        value: state.form.confirmDataPrivacy,
        translate: true
      }
    ])

    return overview
  },
  ...make.getters(state)
}

// like: events
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  addAttachment(state, payload) {
    if (!payload.id && !payload.originalFilename) return
    const attachment = {
      id: payload.id,
      originalFilename: payload.originalFilename
    }
    state.form.inquiry.sendMessage.attachments.push(attachment)
  },
  deleteAttachment(state, index) {
    state.form.inquiry.sendMessage.attachments.splice(index, 1)
  },
  formSent(state) {
    state.form.sent = true
  },
  ...make.mutations(state)
}

// like: commands
const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
  addAttachement({ commit }, payload) {
    commit('addAttachment', payload)
  },
  deleteAttachement({ commit }, index) {
    commit('deleteAttachment', index)
  },
  async sendContact({ commit, state }) {
    let data = {
      general: state.form
    }
    data.general.filenumber = state.form.identifier
    data.general.personalData.dayOfBirth = dateConvert(
      data.general.personalData.dayOfBirth
    )

    if (state.form.sender === 'self') {
      // data.general.senderData = data.general.personalData
      delete data.general.senderData
    }

    if (state.form.inquiry.calculation) {
      data.requestCalculation = true
    }

    if (state.form.inquiry.paymentConfirmation) {
      data.requestPaymentConfirmation = true
    }

    if (state.form.inquiry.proposePayment.enabled) {
      data.proposePayment = state.form.inquiry.proposePayment
      data.proposePayment.date = dateConvert(
        state.form.inquiry.proposePayment.date
      )
    }
    if (state.form.inquiry.sendMessage.enabled) {
      data.sendMessage = state.form.inquiry.sendMessage
    }

    if (state.form.inquiry.changeBankAccount.enabled) {
      data.changeBankAccount = state.form.inquiry.changeBankAccount
    }

    if (state.form.inquiry.changeAddress.enabled) {
      data.changeAddress = state.form.inquiry.changeAddress
    }

    try {
      await contact().post('message', data)
      return commit('formSent')
    } catch (err) {
      if (err.response.status === 400) {
        return commit('SET_ERROR', 'INVALID_PARAMS')
      }
      return commit('SET_ERROR', 'TECHNICALERROR')
    }
  }
}

export default {
  // like: domain
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
