import contact from '@/api/contact'
import { make } from 'vuex-pathify'
import dayjs from 'dayjs'

const getDefaultState = () => {
  return {
    general: {
      filenumber: '',
      company: '',
      confirmForm: false,
      confirmDataPrivacy: false,
      sender: 'self',
      email: '',
      phone: '',
      personalData: {
        givenName: '',
        surname: '',
        dayOfBirth: '',
        streetAddress: '',
        streetNumber: '',
        zip: '',
        city: '',
        country: 'DE'
      },
      senderData: {
        givenName: '',
        surname: '',
        dayOfBirth: '',
        streetAddress: '',
        streetNumber: '',
        zip: '',
        city: '',
        country: 'DE'
      },
      message: '',
      attachments: []
    },
    subsequentPresentation: {
      ticketnumber: '',
      chipcardnumber: ''
    },
    arrangeDeferral: {
      amount: '',
      date: dayjs().format('YYYY-MM-DD')
    },
    arrangeInstalment: {
      amount: '',
      date: dayjs().format('YYYY-MM-DD')
    },
    formtype: '',
    sent: false,
    success: false,
    error: false
  }
}

// const dateConvert = date => {
//   const d = date.split('.')
//   return [d[2], d[1], d[0]].join('-')
// }

// like: initial state
const state = getDefaultState()

// like: aggregates
const getters = {
  ...make.getters(state)
}

// like: events
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  mapUserData(state, user) {
    state.general.personalData.givenName = user.givenName
    state.general.personalData.surname = user.surname
    state.general.personalData.dayOfBirth = dayjs(user.dayOfBirth).format(
      'YYYY-MM-DD'
    )
    state.general.personalData.streetAddress = user.streetAddress
    state.general.personalData.streetNumber = user.streetNumber
    state.general.personalData.zip = user.zip
    state.general.personalData.city = user.city
    state.general.personalData.country = user.country
    if (user.contacts && user.contacts.length) {
      const email = user.contacts.filter(contact => contact.type === 'mail')
      const phone = user.contacts.filter(contact => contact.type === 'phone')
      if (email.length) state.general.email = email[0].value
      if (phone.length) state.general.phone = phone[0].value
    }
  },
  addAttachment(state, payload) {
    if (!payload.id && !payload.originalFilename) return
    const attachment = {
      id: payload.id,
      originalFilename: payload.originalFilename
    }
    state.general.attachments.push(attachment)
  },
  deleteAttachment(state, index) {
    state.general.attachments.splice(index, 1)
  },
  formSent(state) {
    state.sent = true
    state.success = true
  },
  ...make.mutations(state)
}

// like: commands
const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
  addAttachement({ commit }, payload) {
    commit('addAttachment', payload)
  },
  deleteAttachement({ commit }, index) {
    commit('deleteAttachment', index)
  },
  mapUserData({ commit }, payload) {
    commit('mapUserData', payload.user)
  },
  async sendMessage({ commit, state }) {
    const data = {
      general: state.general
    }

    if (state.formtype === 'complaint') {
      data.makeComplaint = true
    }

    if (state.formtype === 'subsequentPresentation') {
      data.subsequentPresentation = state.subsequentPresentation
      data.subsequentPresentation.messageType = 'subsequentPresentation'
      if (data.general.isChipcard) {
        delete data.subsequentPresentation.ticketnumber
      } else {
        delete data.subsequentPresentation.chipcardnumber
      }
    }

    if (state.formtype === 'arrangeDeferral') {
      data.arrangeDeferral = state.arrangeDeferral
    }

    if (state.formtype === 'arrangeInstalment') {
      data.arrangeInstalment = state.arrangeInstalment
    }

    try {
      await contact().post('message', data)
      return commit('formSent')
    } catch (err) {
      if (err.response.status === 400) {
        return commit('SET_ERROR', 'INVALID_PARAMS')
      }
      return commit('SET_ERROR', 'TECHNICALERROR')
    }
  }
}

export default {
  // like: domain
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
