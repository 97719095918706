<template>
  <div id="payAction">
    <div class="container content">
      <div class="columns">
        <div class="column">
          <h1 class="has-margin-top-30">
            {{ $t('payment.general.header') }}
          </h1>
        </div>
      </div>
    </div>
    <div class="has-background-white-ter">
      <div class="container has-padding-top-20 has-padding-bottom-20">
        <span v-html="$t('payment.general.headline', headlineParams)"></span>
        <refresh-cw-icon
          :key="loading"
          size="1x"
          class="refresh has-cursor-pointer has-hover-primary has-margin-left-10"
          :class="{ active: loading }"
          @click="refresh"
        ></refresh-cw-icon>
      </div>
      <div v-if="hasBalance" class="container payment-box">
        <div class="columns is-desktop">
          <!-- Step 1 -->
          <PaymentAmount />
          <!-- Step 2 -->
          <PaymentSelect />
          <!-- Step 3 -->
          <PaymentWidget />
        </div>
      </div>
      <div
        v-else
        class="container content has-padding-top-60 has-padding-bottom-60"
      >
        <p v-if="claim.filenumber">{{ $t('payment.general.already-payed') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { DateTime } from 'luxon'
import { RefreshCwIcon } from 'vue-feather-icons'
import PaymentAmount from '@/components/Payment/Amount'
import PaymentSelect from '@/components/Payment/Select'
import PaymentWidget from '@/components/Payment/Widget'

export default {
  name: 'Payment',
  components: {
    RefreshCwIcon,
    PaymentAmount,
    PaymentSelect,
    PaymentWidget
  },
  data() {
    return {
      date: DateTime.local().toLocaleString(),
      loading: false
    }
  },
  computed: {
    claim: get('portal/claim'),
    claimIdentifier: get('portal/claimIdentifier'),
    headlineParams() {
      return { claim: this.claimIdentifier, date: this.date }
    },
    hasBalance() {
      return this.claim.balance > 0
    }
  },
  methods: {
    requestClaim: call('portal/requestClaim'),
    setPayment: call('payment/setPayment'),
    async refresh() {
      this.loading = true
      await this.requestClaim({
        identifier: this.claimIdentifier,
        refresh: true
      })
      this.setPayment(this.claim)
      setTimeout(() => (this.loading = false), 800)
    }
  }
}
</script>

<style lang="scss" scoped>
.refresh.active {
  animation: spin 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
