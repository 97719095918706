/*
 * vue-inputmask
 *
 * (C) 2019 uscreen GmbH
 * MIT LICENCE
 *
 */
import Inputmask from 'inputmask'

var inputmaskPlugin = {
  install: function(Vue, options) {
    Vue.directive('mask', {
      bind: function(el, binding) {
        var maskOpts = binding.value
        maskOpts.showMaskOnHover = false
        maskOpts.autoUnmask = false
        maskOpts.clearIncomplete = true
        Inputmask(maskOpts).mask(el)
      },
      unbind: function(el) {
        Inputmask.remove(el)
      }
    })
  }
}

export default inputmaskPlugin
