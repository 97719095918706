<template>
  <a
    href="https://de.flow.riverty.com"
    target="_blank"
    class="button has-text-white"
    style="text-decoration: none; background-color: #104b6e"
  >
    Riverty
    <span style="padding-left: 0.25em">
      <ExternalLinkIcon />
    </span>
  </a>
</template>

<script>
import { ExternalLinkIcon } from 'vue-feather-icons'
export default {
  name: 'ButtonInkasso',
  components: { ExternalLinkIcon }
}
</script>

<style lang="scss" scoped></style>
