<template>
  <div class="field">
    <label class="checkbox" @click="onInput()">
      <input type="checkbox" :name="name" v-model="computedValue" @click.stop />
      <span class="check"></span>
      <span class="control-label" v-html="label"></span>
      <span v-if="required">*</span>
      <span v-if="validationState">
        <check-circle-icon
          v-if="validationState === 'success'"
          size="1x"
          class="check-circle-icon"
        ></check-circle-icon>
        <circle-icon
          v-else
          size="1x"
          class="circle-icon"
          :class="validationState === 'invalid' ? 'is-danger' : ''"
        ></circle-icon>
      </span>
    </label>
    <transition
      appear
      enter-active-class="bounceInLeft"
      leave-active-class="fadeOut"
      v-if="errorMessage"
    >
      <span class="tooltip">{{ errorMessage }}</span>
    </transition>
  </div>
</template>

<script>
import { CircleIcon, CheckCircleIcon } from 'vue-feather-icons'

export default {
  components: { CircleIcon, CheckCircleIcon },
  props: {
    label: {
      type: String
    },
    name: {
      type: String
    },
    value: {
      type: Boolean
    },
    required: {
      type: Boolean
    },
    validationState: {
      type: String
    },
    validated: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String
    },
    i18n: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped></style>
