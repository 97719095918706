import { make } from 'vuex-pathify'
import dottie from 'dottie'
import AppConfig from '@/appconfig'

const getDefaultState = () => {
  return {
    identifier: null,
    step: 2,
    amount: 0,
    displayAmount: 0,
    amountType: 'full',
    currency: 'EUR',
    selectedMethod: null,
    availableMethods: [],
    url: null,
    error: false
  }
}

// like: initial state
const state = getDefaultState()

// like: aggregates
const getters = {
  ...make.getters(state),
  widget: state => {
    const method = state.availableMethods.find(m => {
      return m.name === state.selectedMethod ? m.widget : false
    })

    return dottie.get(method, 'widget', false)
  }
}

// like: events
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  ...make.mutations(state)
}

// like: commands
const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
  setPayment({ commit }, payload) {
    const identifier =
      AppConfig.get('identifier.type') === 'customernumber'
        ? dottie.get(payload, 'claimDetails.customerNumber')
        : dottie.get(payload, 'filenumber')

    commit('SET_IDENTIFIER', identifier)
    commit('SET_AMOUNT', payload.balance)
    commit('SET_DISPLAY_AMOUNT', payload.balance)
    commit('SET_CURRENCY', payload.currency)
  },
  setMethods({ commit }, payload) {
    commit('SET_AVAILABLE_METHODS', payload.methods)
  }
}

export default {
  // like: domain
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
