<template>
  <transition
    appear
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
    v-if="message"
  >
    <span :class="css">{{ errorMessage }}</span>
  </transition>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    message: String,
    context: String,
    css: {
      type: Array,
      default() {
        return ['error']
      }
    }
  },
  computed: {
    errorMessage() {
      return this.$t(`form.validations.${this.message}`, {
        _field_: this.$t(`form.labels.${this.context}`)
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
