<template>
  <div>
    <nav
      class="step-nav"
      :class="{
        ['is-borderless']: active,
        ['has-border-bottom has-border-bottom-width-1 has-border-grey-lighter']: !active
      }"
    >
      <div class="columns is-vcentered has-padding-10 is-mobile">
        <div class="column step-number">{{ step }})</div>
        <div class="column is-11 is-paddingless-x is-mobile">
          <slot></slot>
        </div>
      </div>
    </nav>
    <div
      class="step-border has-border-top has-border-top-width-1"
      :class="{
        ['active is-borderless has-background-primary']: active,
        ['has-border-grey-lighter']: !active
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'PaymentStepHeader',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    step: {
      type: Number,
      required: true,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped></style>
