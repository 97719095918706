<template>
  <div>
    <div class ref="demotext" v-html="demoText"></div>

    <Modal :active="modal" :header="true" @modalClosed="modal = false">
      <template v-slot:content>
        <div
          class="image is-4by3 has-background-white-ter"
          style="position: relative"
        >
          <img :src="demoImage" alt />
          <div class="demo-tooltip" :style="demoTooltipStyles.join(';')">
            <div
              v-show="demoTooltip"
              :aria-label="
                $t('easylog.demotooltip', { identifier: identifier })
              "
              data-balloon-pos="left"
              data-balloon-length="large"
            ></div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
export default {
  components: {
    Modal
  },
  props: {
    identifier: {
      type: String,
      required: true,
      default: 'filenumber'
    }
  },
  data() {
    return {
      modal: false
    }
  },
  computed: {
    demoEnabled() {
      return this.$appconfig.get('identifier_demo.enabled', false)
    },
    demoText() {
      return this.$t('easylog.demotext', { link: this.demoLink })
    },
    demoLink() {
      return this.demoEnabled
        ? `<a href="#">${this.$t(this.identifier)}</a>`
        : this.$t(this.identifier)
    },
    demoImage() {
      return this.$appconfig.get(
        'identifier_demo.url',
        'https://bulma.io/images/placeholders/1280x960.png'
      )
    },
    demoTooltip() {
      return this.$appconfig.get('identifier_demo.tooltip', false)
    },
    demoTooltipStyles() {
      return this.$appconfig.get('identifier_demo.tooltipStyles', [])
    }
  },
  methods: {
    showModal() {
      this.modal = true
    }
  },
  mounted() {
    const demotext = this.$refs.demotext
    const link = demotext.querySelector('a')
    if (link) {
      link.addEventListener('click', this.showModal)
    }
  }
}
</script>

<style lang="scss" scoped>
.demo-tooltip {
  display: block;
  position: absolute;
  background-color: rgba(100, 100, 100, 0.6);
  div {
    height: 100%;
  }
}
</style>
