<template>
  <tr v-if="noBalance">
    <td>{{ claimIdentifier }}</td>
    <td colspan="3">
      <p class="notification has-text-centered">
        {{ $t('payment.general.already-payed') }}
      </p>
    </td>
  </tr>
  <tr v-else>
    <td>{{ claimIdentifier }}</td>
    <td>
      <div class="has-text-right">{{ $t('services.label.mainClaim') }}:</div>
      <div class="has-text-right">{{ $t('services.label.interests') }}:</div>
      <div class="has-text-right">{{ $t('services.label.charges') }}:</div>
      <div class="has-text-right">{{ $t('services.label.payments') }}:</div>
      <div class="has-text-right">
        ----------------
      </div>
      <div class="has-text-weight-bold has-text-right">
        {{ $t('services.label.total') }}:
      </div>
    </td>
    <td>
      <div class="has-text-right has-text">
        <MoneyFormat
          :value="claim.balanceDetails.mainClaim"
          :currencyCode="claim.currency"
          :locale="$i18n.locale"
        />
      </div>
      <div class="has-text-right has-text">
        <MoneyFormat
          :value="claim.balanceDetails.interests"
          :currencyCode="claim.currency"
          :locale="$i18n.locale"
        />
      </div>
      <div class="has-text-right has-text">
        <MoneyFormat
          :value="claim.balanceDetails.charges"
          :currencyCode="claim.currency"
          :locale="$i18n.locale"
        />
      </div>
      <div class="has-text-right has-text">
        <span class="no-break">
          <MoneyFormat
            :value="-claim.balanceDetails.payments"
            :currencyCode="claim.currency"
            :locale="$i18n.locale"
          />
        </span>
      </div>
      <div class="has-text-right has-text">-----------</div>
      <div class="has-text-weight-bold has-text-right">
        <MoneyFormat
          :value="claim.balance"
          :currencyCode="claim.currency"
          :locale="$i18n.locale"
        />
      </div>
    </td>
    <td>
      <div class="buttons is-right">
        <button class="button is-primary" @click.prevent="callAction('pay')">
          {{ $t('services.label.pay') }}
        </button>
        <button
          v-for="(action, index) in allowedCosimaActions"
          :key="index"
          class="button is-primary"
          @click.prevent="callAction(action)"
        >
          {{ $t(`services.label.${action}`) }}
        </button>
        <button
          class="button is-primary"
          @click.prevent="callAction('subsequent_presentation')"
        >
          {{ $t('services.label.later_submission') }}
        </button>
        <button
          class="button is-primary"
          @click.prevent="callAction('complaint')"
        >
          {{ $t('services.label.complaint') }}
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { sync, call } from 'vuex-pathify'
import VueScrollTo from 'vue-scrollto'
import MoneyFormat from '@/components/MoneyFormat'
import { portalMixin } from '@/mixins/portal'
import { matomoMixin } from '@/mixins/matomo'

export default {
  name: 'SelfserviceClaim',
  mixins: [matomoMixin, portalMixin],
  components: {
    MoneyFormat
  },
  props: {
    claim: {}
  },
  data() {
    return {
      defaultCosimaActions: ['arrange_deferral', 'arrange_instalment'],
      scrollOptions: {
        easing: 'ease-in',
        offset: -160
      }
    }
  },
  computed: {
    currentAction: sync('portal/currentAction'),
    portalClaim: sync('portal/claim'),
    claimIdentifier() {
      const type = this.$appconfig.get('identifier.type', false)
      return this.portalIdentifier(this.claim, type)
    },
    allowedCosimaActions() {
      // const actions = this.$appconfig.get(
      //   'portal.allowedCosimaActions',
      //   this.defaultCosimaActions
      // )
      // return actions.filter(x => this.claim.actions.includes(x))
      return this.defaultCosimaActions
    },
    noBalance() {
      return !this.claim.balance
    }
  },
  methods: {
    setPayment: call('payment/setPayment'),
    callAction(name) {
      this.currentAction = name
      this.portalClaim = this.claim
      let scrollTo = '#internalAction'
      if (name === 'pay') {
        this.setPayment(this.claim)
        scrollTo = '#payAction'
      }
      this.matomoButtonEvent(name)
      VueScrollTo.scrollTo(scrollTo, '500', this.scrollOptions)
    }
  }
}
</script>

<style lang="scss" scoped></style>
