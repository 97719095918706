<template>
  <label class="label">
    {{ label }}
    <span v-if="required">*</span>
    <check-icon size="1x" class="check-icon" v-if="success"></check-icon>
  </label>
</template>

<script>
import { CheckIcon } from 'vue-feather-icons'
export default {
  components: {
    CheckIcon
  },
  props: {
    label: {
      type: String
    },
    required: {
      type: Boolean
    },
    success: {
      tpe: Boolean
    }
  }
}
</script>

<style lang="scss" scoped></style>
