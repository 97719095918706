<template>
  <select @change="onChange($event.target.value)" :class="className">
    <option
      v-for="(country, index) in orderedCountries"
      :value="Object.keys(country)[0]"
      :selected="Object.keys(country)[0] === value"
      :key="index"
    >
      {{ Object.values(country)[0] }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    className: {
      type: String
    },
    i18n: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    orderedCountries() {
      const countries = this.i18n ? this.translated : this.countries
      const ordered = Object.keys(countries)
        .sort((a, b) => {
          return countries[a].localeCompare(countries[b])
        })
        .map(key => {
          return { [key]: countries[key] }
        })
      return ordered
    },
    translated() {
      let countries = {}
      Object.keys(this.countries).forEach(iso => {
        countries[iso] = this.$t('countries.' + iso)
      })
      return countries
    }
  },
  methods: {
    onChange(country) {
      this.$emit('input', country)
    }
  },
  data() {
    return {
      countries: {
        AU: 'Australia',
        AT: 'Austria',
        BE: 'Belgium',
        BA: 'Bosnia & Herzegovina',
        IO: 'British Indian Ocean Territory',
        VG: 'British Virgin Islands',
        BG: 'Bulgaria',
        IC: 'Canary Islands',
        BQ: 'Caribbean Netherlands',
        CI: 'Côte d`Ivoire',
        HR: 'Croatia',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DK: 'Denmark',
        EE: 'Estonia',
        FI: 'Finland',
        FR: 'France',
        DE: 'Germany',
        GR: 'Greece',
        GL: 'Greenland',
        HU: 'Hungary',
        IS: 'Iceland',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        XK: 'Kosovo',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Laos',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        NL: 'Netherlands',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NG: 'Nigeria',
        NO: 'Norway',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territories',
        PL: 'Poland',
        PT: 'Portugal',
        RO: 'Romania',
        RU: 'Russia',
        RS: 'Serbia',
        SK: 'Slovakia',
        SI: 'Slovenia',
        ES: 'Spain',
        SD: 'Sudan',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syria',
        TN: 'Tunisia',
        TR: 'Turkey',
        UA: 'Ukraine',
        GB: 'United Kingdom',
        US: 'United States'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
