// if (process.env.NODE_ENV === 'production') {
//   require('./assets/sass/main.scss')
//   if (process.env.VUE_APP_THEME) {
//     require(`./assets/sass/themes/${process.env.VUE_APP_THEME}/custom.scss`)
//   }
// }
//
// require(`./assets/sass/themes/${process.env.VUE_APP_THEME}/custom.scss`)
require('./assets/sass/main.scss')

if (process.env.VUE_APP_THEME) {
  require(`./assets/sass/themes/${process.env.VUE_APP_THEME}/custom.scss`)
}
