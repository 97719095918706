<template>
  <div class="container easylog">
    <form class="columns is-centered login" v-on:submit.prevent="submit">
      <div class="column is-half">
        <div
          class="field has-addons has-addons-centered"
          :data-balloon-visible="error ? true : false"
          :aria-label="error ? $t(errorName) : false"
          data-balloon-pos="down"
          data-balloon-length="fit"
          @click="reset"
        >
          <div class="control">
            <input
              v-model="identifier"
              :placeholder="$t(`easylog.${identifierType}-placeholder`)"
              class="input is-primary"
              v-mask="{ mask: mask }"
              type="text"
            />
          </div>
          <div class="control">
            <button class="button" :disabled="!identifier">
              <span v-if="!loading">{{ $t('button.next') }}</span>
              <SyncLoader
                v-else
                class="spinner white"
                :size="11"
                margin="2px"
              />
            </button>
          </div>
        </div>
        <div class="has-text-centered">
          <Helptext :identifier="identifierType" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import Helptext from '@/components/Easylog/Helptext'
import { SyncLoader } from '@saeris/vue-spinners'

export default {
  name: 'Easylog',
  components: {
    Helptext,
    SyncLoader
  },
  data: () => {
    return {
      loading: false
    }
  },
  computed: {
    claim: get('portal/claim'),
    identifier: sync('portal/identifier'),
    identifierType: get('portal/identifierType'),
    error: sync('portal/error'),
    mask() {
      return this.$appconfig.get('identifier.mask', 'a.99.9999999.99.9')
    },
    hasError() {
      return this.error
    },
    errorName() {
      return `errors.${this.error}_${this.identifierType.toUpperCase()}`
    }
  },
  methods: {
    requestClaim: call('portal/requestClaim'),
    async submit() {
      this.loading = true
      await this.requestClaim({
        refresh: true
      })
      if (!this.hasError) {
        this.$router.push({
          name: 'selfserviceClaim',
          params: {
            lang: this.$i18n.locale,
            id: this.identifier,
            accesstype: 'W'
          }
        })
      }
      setTimeout(this.resetSpinner, 200)
    },
    resetSpinner() {
      this.loading = false
    },
    reset() {
      if (this.error) {
        this.error = false
      }
    }
  }
}
</script>

<style lang="css" scoped></style>
