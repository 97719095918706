/**
 * event bus
 *
 * @example
 *
 * bus.emit('someevent', 'somedata')
 * bus.on('someevent', (data) => {...})
 *
 */

const minibus = require('minibus')
const observers = {}

minibus.extension.subscribe = function(observer, trigger, handler) {
  const route = this.on(trigger, handler)
  ;(observers[observer._uid] = observers[observer._uid] || []).push(route)
}

minibus.extension.leave = function(observer) {
  observers[observer._uid].map(route => this.off(route))
  delete observers[observer._uid]
}

const bus = minibus.create()

export default bus
